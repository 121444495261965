import { useGetReceiptsQuery } from "../store/account/api";

const useReceipts = (customerId = undefined) => {
  const {
    data: receipts,
    error: receiptsError,
    isFetching: isFetchingReceipts,
    isSuccess,
  } = useGetReceiptsQuery(customerId);

  return { receipts, receiptsError, isFetchingReceipts, isSuccess };
};

export default useReceipts;
