/* eslint-disable */

/*
inputName: {
  required: {
    value: bool,
    message: string
  },
  pattern: {
    value: regex,
    messag: string
  },
  custom: {
    isValid: fn,
    message: string
  }
}
*/

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegExp =
  /^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/;

const isPhoneNumber = (number) => {
  // trusting implementation
  return number.replace(/\D/g, "").length === 10;
};

const validPasswordMsg =
  "Your password must have at least 10 characters, one uppercase letter, one digit, and one special character";

export const signInValidations = {
  email: {
    pattern: {
      value: emailRegExp,
      message: "Invalid address",
    },
  },
  // password: {
  //   pattern: {
  //     value: passwordRegExp,
  //     message:
  //       validPasswordMsg,
  //   },
  // },
  password: {
    required: {
      value: true,
      message: "Required",
    },
  },
};

export const signUpValidations = {
  email: {
    pattern: {
      value: emailRegExp,
      message: "Invalid address",
    },
  },
  confirmEmail: {
    pattern: {
      value: emailRegExp,
      message: "Invalid address",
    },
  },
  password: {
    pattern: {
      value: passwordRegExp,
      message: validPasswordMsg,
    },
  },
  confirmPassword: {
    pattern: {
      value: passwordRegExp,
      message: validPasswordMsg,
    },
  },
};

export const forgotPasswordValidations = {
  forgotEmail: {
    pattern: {
      value: emailRegExp,
      message: "Invalid address",
    },
  },
};

export const updatePasswordValidations = {
  password: {
    pattern: {
      value: passwordRegExp,
      message: validPasswordMsg,
    },
  },
  confirmPassword: {
    pattern: {
      value: passwordRegExp,
      message: validPasswordMsg,
    },
  },
};

export const summerCamperValidations = {
  firstName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  birthdate: {
    required: {
      value: true,
      message: "Required",
    },
  },
  sex: {
    required: {
      value: true,
      message: "Required",
    },
  },
  shirtSize: {
    required: {
      value: true,
      message: "Required",
    },
  },
};

export const ccatsCamperValidations = {
  firstName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  grade: {
    required: {
      value: true,
      message: "Required",
    },
  },
  sex: {
    required: {
      value: true,
      message: "Required",
    },
  },
  shirtSize: {
    required: {
      value: true,
      message: "Required",
    },
  },
};

export const sessionValidations = {
  programId: {
    required: {
      value: true,
      message: "Required",
    },
  },
  sessionId: {
    required: {
      value: true,
      message: "Required",
    },
  },
};

export const editAccountFormValidations = {
  firstName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Required",
    },
  },
  street: {
    required: {
      value: true,
      message: "Required",
    },
  },
  city: {
    required: {
      value: true,
      message: "Required",
    },
  },
  state: {
    required: {
      value: true,
      message: "Required",
    },
  },
  zip: {
    required: {
      value: true,
      message: "Required",
    },
  },
  cell: {
    required: {
      value: true,
      message: "Required",
    },
    custom: {
      isValid: isPhoneNumber,
      message: "Invalid phone number",
    },
  },
  secondaryFirst: {
    required: {
      value: true,
      message: "Required",
    },
  },
  secondaryLast: {
    required: {
      value: true,
      message: "Required",
    },
  },
  secondaryCell: {
    required: {
      value: true,
      message: "Required",
    },
    custom: {
      isValid: isPhoneNumber,
      message: "Invalid phone number",
    },
  },
  emergencyFirst: {
    required: {
      value: true,
      message: "Required",
    },
  },
  emergencyLast: {
    required: {
      value: true,
      message: "Required",
    },
  },
  emergencyCell: {
    required: {
      value: true,
      message: "Required",
    },
    custom: {
      isValid: isPhoneNumber,
      message: "Invalid phone number",
    },
  },
};

export const editSummerCamperValidations = {
  ...summerCamperValidations,
};

export const editCcatsCamperValidations = {
  ...ccatsCamperValidations,
};

export const editRegValidations = {
  ...sessionValidations,
};

export const newSummerCamperValidations = {
  ...summerCamperValidations,
  ...sessionValidations,
};

export const newCcatsCamperValidations = {
  ...ccatsCamperValidations,
  ...sessionValidations,
};

export const newRegValidations = {
  ...sessionValidations,
};

export const priorSummerCamperValidations = {
  ...summerCamperValidations,
  ...sessionValidations,
};

export const priorCcatsCamperValidations = {
  ...ccatsCamperValidations,
  ...sessionValidations,
};
