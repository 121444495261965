import {
  Button,
  createTheme,
  Paper,
  Stack,
  styled,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

// color palette
// #0D3257,
// #97A7B7,
// #B2C2B9,
// #BDD6E0,
// #65754D,
// #C7DBA9

export const theme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue"',
  },
  palette: {
    primary: {
      main: "#275DB2",
    },
    secondary: {
      main: "#97A7B7",
    },
    accent: {
      main: "#BDD6E0",
    },
    selectedTab: {
      main: "#134678",
    },
    checkout: {
      main: "#C7DBA9",
    },
    action: {
      hover: "#ebebeb",
    },
    tableHead: {
      main: "#333333",
    },
    headers: {
      main: "#183155",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "700",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
  },
});

export const cCatsTheme = createTheme({
  palette: {
    primary: {
      main: "#522D80",
    },
    secondary: {
      main: "#F56600",
    },
    accent: {
      main: "#FFFFFF",
    },
    selectedTab: {
      main: "#6d3caa",
    },
    checkout: {
      main: "#C7DBA9",
    },
    action: {
      hover: "#ebebeb",
    },
    tableHead: {
      main: "#333333",
    },
    headers: {
      main: "#2f1855",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "700",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
  },
});

// HEADER

export const AppTitle = styled(Typography)(({ theme }) => ({
  cursor: "default",
}));

// CONTAINERS

export const ParticipantContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "1rem",
  padding: "1rem",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// BUTTONS

export const GeneralButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  marginTop: "1px",
}));
