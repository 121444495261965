import * as React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// added this back for now because SessionCountdown breaks without it

export default function AlertDialog({
  title,
  text,
  firstOptionText,
  secondOptionText,
  isOpen,
  onClose,
  onFirstOption,
  onSecondOption,
}) {
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onFirstOption} fullWidth>
            {firstOptionText}
          </Button>
          <Button variant="outlined" onClick={onSecondOption} fullWidth>
            {secondOptionText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  firstOptionText: PropTypes.string,
  secondOptionText: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onFirstOption: PropTypes.func,
  onSecondOption: PropTypes.func,
};
