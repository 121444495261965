import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CssBaseline, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import AccountToolbar from "../Account/AccountToolbar";
import AlertMessage from "./AlertMessage";
import AppBar from "@mui/material/AppBar";
import { useGetCustomerProgramTypesQuery } from "../../store/account/api";
import LoadingScreen from "./LoadingScreen";
import ChooseProgramType from "../Account/ChooseProgramType";
import { accountActions } from "../../store/account/slice";
import {
  DEFAULT_PROGRAM_TYPE,
  TYPE_CCATS,
} from "../../store/account/constants";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import BugReportDialog from "./BugReportDialog";
import { useState } from "react";
import { useEffect } from "react";

const AccountLayout = () => {
  const alert = useSelector((state) => state.ui.alert);
  const { customerId, masquerading } = useSelector((state) => state.auth);
  const { programType, programTypeChosen } = useSelector(
    (state) => state.account
  );
  const dispatch = useDispatch();
  const [bugDialogOpen, setBugDialogOpen] = useState(false);

  const {
    data: programTypes,
    error,
    isFetching,
    isSuccess,
    isError,
  } = useGetCustomerProgramTypesQuery(customerId);

  useEffect(() => {
    document.getElementById("background_image").style.display = "none";
  });

  //const broken = undefined.nope;

  if (isFetching) {
    return <LoadingScreen />;
  }

  if (isError) {
    console.error(error.error);
    return <AlertMessage open severity="error" message={error.error} />;
  }

  function updateProgramType(programType, year) {
    dispatch(accountActions.setProgramType(programType));
    dispatch(accountActions.setYear(year));
    dispatch(accountActions.setProgramTypeChosen(true));
  }

  //switch the program type if there are no sessions for the current program type
  if (isSuccess && !masquerading) {
    let hasType = false;
    let type = DEFAULT_PROGRAM_TYPE;
    let year = "";
    programTypes.forEach((pType) => {
      if (!hasType) {
        //if the currently set program type is in the customer's list of program types
        if (pType.name.includes(programType)) {
          hasType = true;
        } else {
          type = pType.name;
          year = pType.year;
        }
      }
    });
    if (!hasType) {
      if (type.includes(TYPE_CCATS)) type = TYPE_CCATS;
      year = new Date().getFullYear();
      updateProgramType(type, year);
    }
  }

  if (isSuccess && programTypes.length < 2 && !programTypeChosen) {
    if (programTypes.length === 1) {
      updateProgramType(programTypes[0].name, programTypes[0].year);
    } else updateProgramType(DEFAULT_PROGRAM_TYPE, new Date().getFullYear());
  }

  function closeBugDialog() {
    setBugDialogOpen(false);
  }

  return programTypeChosen ? (
    <React.Fragment>
      <BugReportDialog
        open={bugDialogOpen}
        closeDialog={closeBugDialog}
        inErrorBoundary={false}
      />
      <CssBaseline />
      {programTypeChosen && (
        <AppBar position="static">
          <Container maxWidth="xl">
            <AccountToolbar />
          </Container>
        </AppBar>
      )}
      <Stack direction="column">
        <Container
          maxWidth="md"
          sx={{
            marginTop: 4,
            background: "white",
            paddingTop: "1px",
            paddingBottom: "15px",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 0,
            marginTop: "15px",
          }}
        >
          <Outlet />
          <AlertMessage
            open={alert.open}
            message={alert.message}
            severity={alert.severity}
          />
        </Container>
        <Container
          maxWidth="md"
          sx={{
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            marginBottom: "16px",
            marginTop: 0,
            bgcolor: "#FFFFFF",
          }}
        >
          <Typography variant="body" sx={{ float: "left" }}>
            Questions? Call 864-878-1103
          </Typography>
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              color: "#069",
              textDecoration: "underline",
              cursor: "pointer",
              fontFamily: "arial, sans-serif",
              fontSize: "12pt",
              float: "right",
            }}
            onClick={() => setBugDialogOpen(true)}
          >
            Report a bug
          </button>
        </Container>
      </Stack>
    </React.Fragment>
  ) : (
    <ChooseProgramType
      programTypes={programTypes}
      onProgramTypeChosen={updateProgramType}
    />
  );
};

export default AccountLayout;
