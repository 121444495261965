import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { accountActions } from "../../store/account/slice";
import "./tabitem.css";
import { MenuItem, Typography } from "@mui/material";
import * as React from "react";
import { ACCOUNT_BASE_URL } from "../../store/account/constants";

const TabMenuItem = ({ tab, handleCloseMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function tabChangeHandler() {
    dispatch(accountActions.changeTab(tab));
    navigate(ACCOUNT_BASE_URL + tab.url);
    handleCloseMenu();
  }

  return (
    <MenuItem key={tab.title} onClick={tabChangeHandler}>
      <Typography textAlign="center">{tab.title}</Typography>
    </MenuItem>
  );
};

export default TabMenuItem;
