import MuiPhoneNumber from "mui-phone-number";
import { FormControl, FormHelperText } from "@mui/material";

export default function PhoneInput({
  name,
  label,
  value,
  onChange,
  defaultCountry = "us",
  onlyCountries = ["us"],
  disableCountryCode = true,
  disableDropdown = true,
  error,
  required = false,
  variant = "outlined",
}) {
  return (
    <FormControl fullWidth>
      <MuiPhoneNumber
        variant={variant}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        defaultCountry={defaultCountry}
        onlyCountries={onlyCountries}
        disableDropdown={disableDropdown}
        disableCountryCode={disableCountryCode}
        error={error}
        required={required}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
}
