import { Switch } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { accountActions } from "../../store/account/slice";
import { useEffect } from "react";

const ToggleSwitch = ({ options }) => {
  const { programType } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  //just for fun
  // useEffect(() => {
  //   setTimeout(() => {
  //     toggleProgramType();
  //   }, 50);
  // });

  function toggleProgramType() {
    if (programType === options[0].name) {
      dispatch(accountActions.setProgramType(options[1].name));
      dispatch(accountActions.setYear(options[1].year));
    } else {
      dispatch(accountActions.setProgramType(options[0].name));
      dispatch(accountActions.setYear(options[0].year));
    }
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ marginLeft: "15px" }}>
      <Typography variant="caption">{options[0].name}</Typography>
      <Switch
        checked={programType === options[1].name}
        color="secondary"
        onChange={toggleProgramType}
      />
      <Typography variant="caption">{options[1].name}</Typography>
    </Stack>
  );
};

export default ToggleSwitch;
