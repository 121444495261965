import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import FileDropZone from "./FileDropZone";
import { Fragment, useState } from "react";
import {
  FORM_APPROVED,
  FORM_NEEDED,
  FORM_PENDING,
  FORM_REJECTED,
  statusColors,
  statusIcons,
} from "../../../store/account/constants";
import ChooseFilesButton from "./ChooseFilesButton";
import ChosenFilesList from "./ChosenFilesList";
import { useDispatch, useSelector } from "react-redux";
import { downloadSingleForm } from "../../../store/account/thunks";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { createPortal } from "react-dom";
import { Modal } from "@mui/material";
import { Button } from "@mui/material";

const CamperDocumentBox = ({ camper, files, onFileAdd }) => {
  const [showingComments, setShowingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const smallScreen = useMediaQuery("(max-width:750px)");
  const { programType } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallScreen ? "90%" : "740px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
  };

  const CommentsModal = () => {
    return createPortal(
      <Modal open={showingComments} onClose={() => setShowingComments(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Rejected Form Comments</Typography>
          <Divider />
          <List>
            {comments.map((comment, index) => (
              <ListItem key={index}>
                <ListItemText primary={comment} />
              </ListItem>
            ))}
          </List>
          <Button
            onClick={() => setShowingComments(false)}
            sx={{ marginTop: "15px" }}
          >
            OK
          </Button>
        </Box>
      </Modal>,
      document.getElementById("modal-root")
    );
  };

  function showRejectedComment() {
    setComments(getComments());
    setShowingComments(true);
  }

  //gets which C-CATS program type the camper is in (otherwise returns the programType from the Redux store)
  function getProgramType() {
    let type = programType;
    if (type.includes("C-CATS")) {
      camper.sessions.forEach((session) => {
        if (session.programType.includes("C-CATS")) {
          type = session.programType;
        }
      });
    }
    return type;
  }

  function getStatus() {
    if (
      camper.documentInfo.some(
        (document) => document.statusDesc === FORM_NEEDED
      )
    )
      return FORM_NEEDED;
    else if (
      camper.documentInfo.some(
        (document) => document.statusDesc === FORM_REJECTED
      )
    )
      return FORM_REJECTED;
    else if (
      camper.documentInfo.some(
        (document) => document.statusDesc === FORM_PENDING
      )
    )
      return FORM_PENDING;
    else return FORM_APPROVED;
  }

  function getComments() {
    let comments = [];
    camper.documentInfo.forEach((document) => {
      if (document.comments) {
        comments.push(document.comments);
      }
    });
    return comments;
  }

  function getDocumentListItem(document, index) {
    return (
      <Fragment key={index}>
        <ListItemButton
          onClick={() =>
            dispatch(
              downloadSingleForm({
                participantId: camper.id,
                programType,
                formId: document.form_id,
              })
            )
          }
        >
          <ListItemIcon>{statusIcons[document.statusDesc]}</ListItemIcon>
          <ListItemText primary={document.name} />
          <DownloadIcon />
        </ListItemButton>
      </Fragment>
    );
  }

  function LargeBox() {
    return (
      <Card sx={{ border: "1px solid #333333" }}>
        <Box
          sx={{
            marginBottom: "10px",
            background: "#333333",
            color: "white",
            padding: "10px",
            height: "65px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={7}>
              <Typography variant="h4">{camper.fullName}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Avatar
                  sx={{
                    bgcolor: statusColors[getStatus()],
                    width: 24,
                    height: 24,
                  }}
                >
                  &nbsp;
                </Avatar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    color: statusColors[getStatus()],
                  }}
                >
                  {getStatus()}
                </div>
                {getStatus() === FORM_REJECTED && (
                  <IconButton
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                    onClick={() => showRejectedComment()}
                  >
                    <HelpIcon sx={{ color: "white" }} />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              paddingBottom: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
              height: "100%",
              minHeight: "200px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <Typography variant="h8">Download Required Forms:</Typography>
              <List>
                {camper.documentInfo.map((document, index) => {
                  return getDocumentListItem(document, index);
                })}
              </List>
              {/*<DownloadBtn id={camper.id} programType={getProgramType()} />*/}
            </Box>
            <FileDropZone
              key={camper.id}
              camperId={camper.id}
              name={camper.fullName}
              addFile={onFileAdd}
              files={files}
            />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  function SmallBox() {
    return (
      <Card sx={{ border: "1px solid #333333" }}>
        <Box
          sx={{
            marginBottom: "10px",
            background: "#333333",
            color: "white",
            padding: "10px",
            height: "65px",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={7}>
              <Typography variant="h5">{camper.fullName}</Typography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Avatar
                  sx={{
                    bgcolor: statusColors[getStatus()],
                    width: 24,
                    height: 24,
                  }}
                >
                  &nbsp;
                </Avatar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    color: statusColors[getStatus()],
                  }}
                >
                  {getStatus()}
                </div>
                {getStatus() === FORM_REJECTED && (
                  <IconButton
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                    onClick={() => showRejectedComment()}
                  >
                    <HelpIcon sx={{ color: "white" }} />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            height: "100%",
            minHeight: "200px",
          }}
        >
          <Box>
            <Typography variant="h8">Download Required Forms:</Typography>
            <List>
              {camper.documentInfo.map((document, index) => {
                return getDocumentListItem(document, index);
              })}
            </List>
            {/*<DownloadBtn id={camper.id} />*/}
          </Box>
          <Typography variant="h8">Upload Completed Forms:</Typography>
          <ChooseFilesButton
            camperId={camper.id}
            addFile={onFileAdd}
            files={files}
          />
          <ChosenFilesList files={files} />
        </Stack>
      </Card>
    );
  }

  return (
    <Fragment>
      <CommentsModal />
      {smallScreen ? <SmallBox /> : <LargeBox />}
    </Fragment>
  );
};

export default CamperDocumentBox;
