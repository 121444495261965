import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const validateStatus = (response) => {
  return response.ok;
};

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkDiscountCode: builder.mutation({
      query: (data) => ({
        url: "check_discount_code",
        method: "POST",
        body: data,
      }),
      transformResponse: (discount) => ({
        ...discount,
        value: parseFloat(discount.value),
      }),
    }),
    // TODO handle case where session closes before cart is submitted
    submitCart: builder.mutation({
      query: (data) => ({
        url: "submit_cart",
        method: "POST",
        body: data,
      }),
      transformResponse: (message) => message,
    }),
  }),
});

export const { useCheckDiscountCodeMutation, useSubmitCartMutation } = cartApi;
