export const domUtils = {
  getEl: (selector) => window.document.querySelector(selector),

  hasClass: (el, cssClass) => {
    if (el.classList) {
      return el.classList.contains(cssClass);
    }
    return !!el.className.match(new RegExp(`(\\s|^)${cssClass}(\\s|$)`));
  },

  removeClass: (el, cssClass) => {
    if (el.classList) {
      el.classList.remove(cssClass);
    } else if (domUtils.hasClass(el, cssClass)) {
      const reg = new RegExp(`(\\s|^)${cssClass}(\\s|$)`);
      el.className = el.className.replace(reg, " ");
    }
  },
};

const fourDigitYear = new Date().getFullYear() + 2;
const placeholderExp = `09/${fourDigitYear.toString().slice(-2)}`;
export const config = {
  fields: {
    card: {
      selector: "[data-cc-card]",
      placeholder: "4012 8888 8888 1881",
    },
    cvv: {
      selector: "[data-cc-cvv]",
      placeholder: "123",
    },
    exp: {
      selector: "[data-cc-exp]",
      placeholder: placeholderExp,
    },
    name: {
      selector: "[data-cc-name]",
      placeholder: "James Smith",
    },
  },

  // css classes to be injected into the iframes.
  // the properties allowed are restricted via whitelist.
  // further, unrestricted styling can be applied to the div's in which the iframes are injected.
  styles: {
    input: {
      color: "rgba(0, 0, 0, 0.87)",
      "font-family": "Roboto, Helvetica, Arial, sans-serif",
      "font-size": "16px",
      background: "black",
    },
    ":focus": {
      color: "#00a9e0",
    },
    ".valid": {
      color: "#43B02A",
    },
    ".invalid": {
      color: "#C01324",
    },
    "input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 50px white inset",
    },
    "input:focus:-webkit-autofill": {
      "-webkit-text-fill-color": "#00a9e0",
    },
    "input.valid:-webkit-autofill": {
      "-webkit-text-fill-color": "#43B02A",
    },
    "input.invalid:-webkit-autofill": {
      "-webkit-text-fill-color": "#C01324",
    },
    "input::placeholder": {
      color: "#aaa",
    },
  },

  // these values correspond to css class names defined above
  classes: {
    empty: "empty",
    focus: "focus",
    invalid: "invalid",
    valid: "valid",
  },
};
