import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { uiActions } from "../../store/ui/slice";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Fade } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const PromptDialog = () => {
  const dispatch = useDispatch();
  const { open, title, message, onNo, onYes, data } = useSelector(
    (state) => state.ui.promptDialog
  );
  const { programType } = useSelector((state) => state.account);

  const handleClose = () => {
    dispatch(uiActions.closeWarningDialog());
  };

  const handleNo = () => {
    onNo(dispatch);
  };

  const handleYes = () => {
    onYes(dispatch, data, programType);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PromptDialog;
