import { Skeleton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";

const DashboardSkeleton = () => {
  return (
    <Fragment>
      <h2>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ marginTop: "35px", width: "315px" }}
        />
      </h2>
      <p>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ marginTop: "25px", width: "415px" }}
        />
      </p>
      <Stack direction="row" spacing={2} sx={{ marginTop: "25px" }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ width: "100%", height: "300px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ width: "100%", height: "300px" }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ width: "100%", height: "300px" }}
        />
      </Stack>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ marginTop: "30px", width: "100%", height: "300px" }}
      />
    </Fragment>
  );
};

export default DashboardSkeleton;
