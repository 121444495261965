import { Button, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackButton = () => {
  const { backAction, backText } = useSelector((state) => state.ui);

  return (
    <Stack
      direction="row"
      spacing={1}
      onClick={backAction}
      sx={{ cursor: "pointer" }}
    >
      <ArrowBackIcon sx={{ color: "#888B8D" }} />
      <Typography variant="body1" color="#888B8D" sx={{ fontWeight: "bold" }}>
        {backText}
      </Typography>
    </Stack>
  );
};

export default BackButton;
