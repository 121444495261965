import { Button, Divider, Grid, Typography } from "@mui/material";
import TextInput from "../../UI/TextInput";
import SelectInput from "../../UI/SelectInput";
import { STATE_OPTIONS } from "../../../helpers/list-options";
import { GeneralButton } from "../../../styles";
import ChangePassword from "../../Authentication/ChangePassword";
import { Container } from "@mui/system";
import Loader from "../../UI/Loader";
import PhoneInput from "../../UI/PhoneInput";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth/slice";

const EditAccountForm = ({
  form,
  errors,
  onChange,
  onSubmit,
  onVerifyAddress,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { wantsPasswordChange } = useSelector((state) => state.auth);

  const setWantsPasswordChange = () => {
    dispatch(authActions.setWantsPasswordChange());
  };

  const clearWantsPasswordChange = () => {
    dispatch(authActions.clearWantsPasswordChange());
  };

  function handlePhoneChange(name, value) {
    onChange({ target: { name, value } });
  }

  const renderButton = () => {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <GeneralButton type="submit" variant="contained">
        Save Contact Information
      </GeneralButton>
    );
  };

  if (wantsPasswordChange) {
    return (
      <>
        <ChangePassword forSecurity={false} />
        <Container maxWidth="xs">
          <Button
            variant="contained"
            color="accent"
            onClick={clearWantsPasswordChange}
            sx={{ marginTop: "12px", width: "100%" }}
          >
            Cancel
          </Button>
        </Container>
      </>
    );
  }

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      rowSpacing={2}
      columnSpacing={4}
    >
      <Grid item m={2} xs={12}>
        <Divider>
          <Typography variant="h5">Login Info</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="email"
          label="Email"
          value={form.email}
          disabled={true}
          variant="outlined"
        />
      </Grid>
      <Grid container item justifyContent="center" xs={12} sm={6}>
        <Button
          variant="contained"
          color="accent"
          onClick={setWantsPasswordChange}
        >
          Change Password
        </Button>
      </Grid>
      <Grid item m={2} xs={12}>
        <Divider>
          <Typography variant="h5">Your Contact Info</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="firstName"
          label="First Name"
          value={form.firstName}
          onChange={onChange}
          error={errors.firstName}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="lastName"
          label="Last Name"
          value={form.lastName}
          onChange={onChange}
          error={errors.lastName}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="street"
          label="Street Address"
          value={form.street}
          onChange={onChange}
          error={errors.street}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="city"
          label="City"
          value={form.city}
          onChange={onChange}
          error={errors.city}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          name="state"
          label="State"
          value={form.state}
          onChange={onChange}
          options={STATE_OPTIONS}
          error={errors.state}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="number"
          name="zip"
          label="ZIP Code"
          value={form.zip}
          onChange={onChange}
          error={errors.zip}
          variant="outlined"
          maxLength={5}
        />
      </Grid>
      <Grid container item justifyContent="center" xs={12} sm={6}>
        <Button variant="contained" color="accent" onClick={onVerifyAddress}>
          Verify Address
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneInput
          name="cell"
          label="Cell Phone Number"
          value={form.cell}
          onChange={(value) => handlePhoneChange("cell", value)}
          error={errors.cell}
        />
      </Grid>
      <Grid item m={2} xs={12}>
        <Divider>
          <Typography variant="h5">Secondary Contact</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="secondaryFirst"
          label="First Name"
          value={form.secondaryFirst}
          onChange={onChange}
          error={errors.secondaryFirst}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="secondaryLast"
          label="Last Name"
          value={form.secondaryLast}
          onChange={onChange}
          error={errors.secondaryLast}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneInput
          name="secondaryCell"
          label="Cell Phone Number"
          value={form.secondaryCell}
          onChange={(value) => handlePhoneChange("secondaryCell", value)}
          error={errors.secondaryCell}
        />
      </Grid>
      <Grid item m={2} xs={12}>
        <Divider>
          <Typography variant="h5">Third Contact</Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="emergencyFirst"
          label="First Name"
          value={form.emergencyFirst}
          onChange={onChange}
          error={errors.emergencyFirst}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          type="text"
          name="emergencyLast"
          label="Last Name"
          value={form.emergencyLast}
          onChange={onChange}
          error={errors.emergencyLast}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PhoneInput
          name="emergencyCell"
          label="Cell Phone Number"
          value={form.emergencyCell}
          onChange={(value) => handlePhoneChange("emergencyCell", value)}
          error={errors.emergencyCell}
        />
      </Grid>
      <Grid container item justifyContent="center" alignItems="center" xs={12}>
        {renderButton()}
      </Grid>
    </Grid>
  );
};

export default EditAccountForm;
