import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";

const BugReportForm = ({ onSubmit, onCancel }) => {
  const [description, setDescription] = useState("");

  return (
    <Stack direction="column">
      <div>
        <h2>Report a Bug</h2>
        <p>
          Please describe the bug you encountered and any steps you took to
          produce it.
        </p>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows="10"
          cols="50"
        />
      </div>
      <Stack direction="row" spacing={2}>
        <Button onClick={() => onSubmit(description)}>Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Stack>
    </Stack>
  );
};

export default BugReportForm;
