import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const SecondaryHeading = ({ text }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", textAlign: "left", padding: 0 }}>
      <Typography
        variant="h6"
        align="left"
        color="#888B8D"
        sx={{ width: "100%", fontWeight: "bold" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SecondaryHeading;
