import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate } from "react-router-dom";
import {
  ACTIVE_STATUS,
  CAMPER_PAYMENT_URL,
  CAMPER_REG_URL,
  EDIT_CAMPER_URL,
  TYPE_CCATS,
} from "../../../store/account/constants";
import { withStyles } from "@mui/styles";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledTableRow } from "../../../styles";
import { Fragment, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RegistrationRow from "./RegistrationRow";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const RegistrationsTable = ({ camper, past }) => {
  const [totalBalance, setTotalBalance] = useState(0);
  const navigate = useNavigate();
  const sessions = past ? camper.pastSessions : camper.sessions;
  const smallScreen = useMediaQuery("(max-width:750px)");
  const [optionsAnchor, setOptionsAnchor] = useState(null);
  const theme = useTheme();
  const { programType } = useSelector((state) => state.account);

  function handleOpenOptions(event) {
    setOptionsAnchor(event.currentTarget);
  }

  const handleCloseOptions = () => {
    setOptionsAnchor(null);
  };

  function paymentButtonHandler(event) {
    navigate(CAMPER_PAYMENT_URL + camper.id);
  }

  function addCampButtonHandler(event) {
    if (programType === TYPE_CCATS)
      navigate("/register/ccats?camper=" + camper.id);
    else navigate(CAMPER_REG_URL + camper.id);
  }

  const HeadersRow = withStyles((theme) => ({
    root: {
      height: "4ch",
      background: "#C8C9C7",
    },
  }))(TableRow);

  const HeaderCell = withStyles((theme) => ({
    root: {
      padding: "0px 16px",
    },
  }))(TableCell);

  useEffect(() => {
    let total = 0;
    sessions
      .filter((session) => session.status === ACTIVE_STATUS)
      .forEach((session) => {
        total += session.totalDue;
      });

    setTotalBalance(total);
  });

  function isOverdue(session) {
    return (
      //add 24 hours to due date because it's at midnight on the start of the day
      session.totalDue > 0 &&
      new Date(session.paymentDueDate).getTime() + 86399000 <
        new Date().getTime()
    );
  }

  /**
   * Larger table for larger screens (desktop, laptop)
   */
  function LargeTable() {
    return (
      <TableContainer
        style={{ marginBottom: "25px" }}
        component={Paper}
        key={camper.id}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow style={{ background: theme.palette.tableHead.main }}>
              <TableCell style={{ color: "white", maxWidth: "310px" }}>
                <Typography variant="h5" minWidth="200px" noWrap>
                  {camper.fullName}
                </Typography>
              </TableCell>
              <TableCell colSpan={2} style={{ color: "white" }}>
                {!past && (
                  <span
                    style={{
                      float: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      id={camper.id}
                      style={{
                        height: "45px",
                        marginRight: "2px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      onClick={addCampButtonHandler}
                    >
                      Add a Camp
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      id={camper.id}
                      style={{
                        height: "45px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                      onClick={paymentButtonHandler}
                    >
                      Make a Payment
                    </Button>
                  </span>
                )}
              </TableCell>
            </TableRow>
            <HeadersRow>
              <HeaderCell>CAMP</HeaderCell>
              {!past && <HeaderCell>PAYMENT DUE DATE</HeaderCell>}
              <HeaderCell align="right">{!past && "BALANCE"}</HeaderCell>
            </HeadersRow>
          </TableHead>
          <TableBody>
            {sessions &&
              sessions
                .filter((session) => session.status === ACTIVE_STATUS)
                .map((session) => {
                  return (
                    <StyledTableRow key={session.id}>
                      <TableCell>
                        <Stack direction="column">
                          {session.name + ": " + session.num}
                          <Typography variant="caption">
                            {`${session.dates}${
                              past ? " " + session.year : ""
                            }`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      {!past && (
                        <TableCell>
                          <Stack direction="column">
                            {session.paymentDueDate}
                            {isOverdue(session) && (
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}
                              >
                                Payment Overdue
                              </Typography>
                            )}
                          </Stack>
                        </TableCell>
                      )}
                      {!past && (
                        <TableCell align="right">${session.totalDue}</TableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
            {!past && (
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  {totalBalance <= 0
                    ? "Paid in full"
                    : `Total: $${totalBalance}`}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  /**
   * Narrow table for smaller screens (mobile)
   */
  function SmallTable() {
    return (
      <TableContainer
        style={{ marginTop: "25px" }}
        component={Paper}
        key={camper.id}
      >
        <Table>
          <TableHead sx={{ background: "#333333" }}>
            <TableRow sx={{ background: "#333333" }}>
              <TableCell colSpan={1} sx={{ color: "white" }}>
                <Typography variant="h4" noWrap>
                  {camper.fullName}
                </Typography>
              </TableCell>
              {!past && (
                <Fragment>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      aria-controls="menu-options"
                      aria-haspopup="true"
                      onClick={handleOpenOptions}
                    >
                      <MoreVertIcon sx={{ color: "white" }} />
                    </IconButton>
                  </TableCell>
                  <Menu
                    id="menu-options"
                    anchorEl={optionsAnchor}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(optionsAnchor)}
                    onClose={handleCloseOptions}
                  >
                    <MenuItem>
                      <Typography textAlign="center">Add a Camp</Typography>
                    </MenuItem>
                    <MenuItem onClick={paymentButtonHandler}>
                      <Typography textAlign="center">Make Payment</Typography>
                    </MenuItem>
                  </Menu>
                </Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions &&
              sessions
                .filter((session) => session.status === ACTIVE_STATUS)
                .map((session) => {
                  return (
                    <StyledTableRow key={session.id} sx={{ fontSize: "24pt" }}>
                      <RegistrationRow session={session} past={past} />
                    </StyledTableRow>
                  );
                })}
            {!past && (
              <TableRow>
                <TableCell colSpan={past ? 1 : 2} align="right">
                  {totalBalance <= 0
                    ? "Paid in full"
                    : `Total: $${totalBalance}`}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return smallScreen ? <SmallTable /> : <LargeTable />;
};

export default RegistrationsTable;
