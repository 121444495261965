import Receipt from "./Receipt";
import useReceipts from "../../../hooks/useReceipts";
import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TableBody } from "@mui/material";
import TabHeader from "../TabHeader";

const ReceiptsTab = () => {
  // const receipts = useSelector(state => state.account.receipts)

  // const { data: receipts, error, isLoading, isSuccess, isError } = useGetReceiptsQuery()

  const navigate = useNavigate();

  const { receipts, receiptsError, isFetchingReceipts, isSuccess } =
    useReceipts();

  if (isFetchingReceipts) return <div>Loading Receipts...</div>;

  if (receiptsError) {
    return (
      <div>
        Error loading receipts
        {console.log("error: " + receiptsError?.message)}
      </div>
    );
  }

  function NoReceipts() {
    return (
      <div
        style={{
          marginTop: "16px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "row",
        }}
      >
        <Stack direction="column">
          <h2>No Receipts Found</h2>
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginBottom: "16px" }}
            onClick={() => {
              navigate("/account/payment");
            }}
          >
            Make a Payment
          </Button>
        </Stack>
      </div>
    );
  }

  function tabContent() {
    if (Array.isArray(receipts)) {
      if (receipts.length === 0) {
        return <NoReceipts />;
      } else {
        return (
          <>
            <TabHeader text={"Receipts"} />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Amount Paid</b>
                    </TableCell>
                    <TableCell>
                      <b>Payment Date</b>
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receipts.map((receipt, index) => (
                    //<Receipt key={receipt.receiptId} receipt={receipt} />
                    <TableRow key={receipt.receiptId}>
                      <TableCell>${receipt.totalPayment}</TableCell>
                      <TableCell>{receipt.date}</TableCell>
                      <TableCell>
                        <Button
                          key={receipt.receiptId}
                          href={`/receipt/${receipt.receiptId}`}
                          target="blank"
                          variant="contained"
                        >
                          View Receipt
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      }
    } else {
      return <div>Well this shouldn&apos;t happen</div>;
    }
  }

  return isSuccess && tabContent();
};

export default ReceiptsTab;
