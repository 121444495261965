const useStateValidation = (validations, setErrors) => {
  const handleValidation = (form) => {
    let isValid = true;
    const newErrors = {};
    if (validations) {
      for (const key in validations) {
        const value = form[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          isValid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          isValid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          isValid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!isValid) {
        setErrors(newErrors);
      }
    }

    return { isValid, newErrors };
  };

  return handleValidation;
};

export default useStateValidation;
