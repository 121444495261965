import { Divider, Grid, Stack } from "@mui/material";
import { Fragment, useEffect } from "react";
import useCamperInfo from "../../../hooks/useCamperInfo";
import useCustomerInfo from "../../../hooks/useCustomerInfo";
import TabHeader from "../TabHeader";
import CamperRegistrations from "./CamperRegistrations";
import ParentDetailsBox from "./InfoBoxes/ParentDetailsBox";
import PaymentDueBox from "./InfoBoxes/PaymentDueBox";
import TaskChecklist from "./InfoBoxes/TaskChecklist";
import { uiActions } from "../../../store/ui/slice";
import { useDispatch, useSelector } from "react-redux";
import DashboardSkeleton from "./DashboardSkeleton";

const DashboardTab = () => {
  const {
    camperInfo: campers,
    camperError,
    isFetchingCamperInfo,
    isErrorCamperInfo,
    isSuccessCamperInfo,
  } = useCamperInfo();
  const dispatch = useDispatch();

  const { programType, year } = useSelector((state) => state.account);

  const { customerInfo, customerError, isFetchingCustomerInfo } =
    useCustomerInfo();

  useEffect(() => {
    if (isErrorCamperInfo) {
      dispatch(uiActions.setAlertError(camperError));
    }
  }, [isErrorCamperInfo]);

  if (isFetchingCamperInfo || isFetchingCustomerInfo) {
    return <DashboardSkeleton />;
  }

  if (!campers) {
    return <div>No campers found</div>;
  }

  return (
    <Fragment>
      <TabHeader text={`${year} ${programType}`} />
      <p>Thanks for choosing our camps again this year!</p>
      {campers.length > 0 && (
        <Grid
          container
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={2}
          sx={{ padding: "5px" }}
        >
          <Grid item xs={4}>
            <TaskChecklist
              variant="list"
              campers={campers}
              customerInfo={customerInfo}
            />
          </Grid>
          <Grid item xs={4}>
            <PaymentDueBox campers={campers} />
          </Grid>
          <Grid item xs={4}>
            <ParentDetailsBox campers={campers} />
          </Grid>
        </Grid>
      )}
      <h2 style={{ marginBottom: 0 }}>
        My {year} {programType} Registrations
      </h2>
      <Divider sx={{ marginBottom: "10px" }} />
      <CamperRegistrations campers={campers} />
    </Fragment>
  );
};

export default DashboardTab;
