import { useMediaQuery } from "@mui/material";
import React from "react";
import RegistrationsTable from "./RegistrationsTable";

const PastRegistrations = ({ campers }) => {
  return campers
    .filter((camper) => camper.pastSessions.length > 0)
    .map((camper) => {
      return <RegistrationsTable key={camper.id} camper={camper} past={true} />;
    });
};

export default PastRegistrations;
