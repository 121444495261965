import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { cartActions } from "../../store/cart/slice";
import { CHECKOUT_STEP, MSG_REGISTERED } from "../../store/ui/constants";
import { uiActions } from "../../store/ui/slice";
import PaymentForm from "./PaymentForm";

import { Button, Stack } from "@mui/material";
import DiscountCode from "./DiscountCode";
import { regActions } from "../../store/register/slice";
import { useSubmitCartMutation } from "../../store/cart/api";
import PaymentSummary from "./PaymentSummary";
import SecondaryHeading from "../UI/SecondaryHeading";

// container for all components that handle collecting payment info
// handles cart submission for processing payments and registrations
const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    campers,
    registrations,
    totalPrice,
    totalDiscount,
    discountCode,
    digitalSignature,
  } = useSelector((state) => state.cart);
  const programType = useSelector((state) => state.account.programType);

  const ptRegistrations = registrations.filter((reg) =>
    reg.programType.includes(programType)
  );
  const ptCampers = campers.filter((camper) => {
    let hasProgramType = false;
    registrations.forEach((reg) => {
      if (reg.camperKey === camper.key) {
        if (reg.programType.includes(programType)) {
          hasProgramType = true;
        }
      }
    });
    return hasProgramType;
  });

  useEffect(() => {
    if (!digitalSignature) {
      dispatch(
        uiActions.setAlertInfo(
          "Please review the terms and conditions before continuing"
        )
      );
      navigate("/");
    }
  }, [digitalSignature, navigate]);

  useEffect(() => {
    if (ptRegistrations.length < 1) {
      // no registrations to process
      dispatch(uiActions.setAlertInfo("Add registrations before checking out"));
      navigate("/");
    }
  }, [ptRegistrations.length]);

  useEffect(() => {
    dispatch(cartActions.updatePayAmounts(programType));
  }, []);

  const [
    submitCart,
    {
      data: submitResp,
      isUninitialized: submitIsUninitialized,
      isSuccess: submitIsSuccess,
      error: submitError,
      isError: submitIsError,
    },
  ] = useSubmitCartMutation();

  useEffect(() => {
    dispatch(uiActions.setActiveRegisterStep(CHECKOUT_STEP));
    dispatch(uiActions.setBackAction(handleBack));
    dispatch(uiActions.setBackText("BACK"));
  }, []);

  // handle submit cart response
  useEffect(() => {
    console.log(submitResp);
    if (!submitIsUninitialized && submitIsSuccess) {
      dispatch(uiActions.setAlertSuccess(MSG_REGISTERED));
      dispatch(cartActions.resetForProgramType(programType));
      navigate("/account");
    } else if (!submitIsUninitialized && submitIsError) {
      console.error(submitError);
      dispatch(uiActions.setAlertError(submitError.data));
    }
  }, [
    submitIsUninitialized,
    submitIsSuccess,
    submitResp,
    submitIsError,
    submitError,
  ]);

  // submit api request to process payment and registrations
  const handleSubmit = (payeezyData) => {
    const data = {
      ...payeezyData,
      campers: ptCampers,
      registrations: ptRegistrations,
      totalPrice,
      totalDiscount,
      discountCode,
      digitalSignature,
    };

    console.log(data);
    submitCart(data);
  };

  const handleBack = () => {
    dispatch(regActions.setupChooseRegisterAction());
    navigate("/");
  };

  return (
    <Stack spacing={2}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
        />
      </svg> */}
      <PaymentSummary />
      {/* <DiscountCode /> */}
      <Stack spacing={2}>
        <SecondaryHeading text="Payment Details" />
        <PaymentForm
          isRegistering={true}
          totalPrice={totalPrice}
          onSubmit={handleSubmit}
          onSubmitMsg="Processing payment"
          totalDiscount={totalDiscount}
        />
      </Stack>
    </Stack>
  );
};

export default CheckoutPage;
