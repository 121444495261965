import { Button, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import DashboardBox from "../../../UI/DashboardBox";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MakePayment from "../../Payment/MakePayment";
import { createPortal } from "react-dom";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const PaymentDueBox = ({ campers }) => {
  const [totalDue, setTotalDue] = useState(0);
  const [nextPaymentDate, setNextPaymentDate] = useState();
  const [payDateString, setPayDateString] = useState();
  const [nextSessionsToPay, setNextSessionToPay] = useState([]);
  const [overdueSessions, setOverdueSessions] = useState([]);
  const [otherSessions, setOtherSessions] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const now = new Date().getTime();
  const smallScreen = useMediaQuery("(max-width:750px)");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallScreen ? "90%" : "740px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
  };

  // calculate total amount due for all customer's registrations
  // also find the soonest upcoming payment due date
  useEffect(() => {
    let total = 0;
    let nextDate = Infinity;
    let nextSessions = [];
    let overdueSessions = [];
    campers.forEach((camper) => {
      camper.sessions.forEach((session) => {
        total += session.totalDue;

        //11:59:59 on the due date
        let sessionDueDate =
          new Date(session.paymentDueDate).getTime() + 86399000;

        //finding sessions with overdue payments
        if (sessionDueDate < now && session.totalDue > 0) {
          overdueSessions.push(session);
        }

        //finding the soonest next due date
        if (sessionDueDate <= nextDate && session.totalDue > 0) {
          if (sessionDueDate === nextDate) {
            //multiple sessions due on the same day
            nextSessions.push(session);
          } else {
            //current session in loop has earlier due date
            nextSessions = [session];
            nextDate = sessionDueDate;
          }
        }
      });
    });

    let other = [];
    campers.forEach((camper) => {
      camper.sessions
        .filter((session) => {
          return (
            !nextSessions.includes(session) &&
            !overdueSessions.includes(session)
          );
        })
        .forEach((session) => other.push(session));
    });

    setTotalDue(total);
    setNextPaymentDate(nextDate);
    setPayDateString(
      new Date(nextDate).toLocaleDateString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
      })
    );
    setNextSessionToPay(nextSessions);
    setOverdueSessions(overdueSessions);
    setOtherSessions(other);
  }, [campers]);

  function showPaymentModal() {
    setModalVisibility(true);
  }

  function hidePaymentModal() {
    setModalVisibility(false);
  }

  function upcomingPaymentTotal() {
    let total = 0;
    nextSessionsToPay.forEach((session) => {
      total += session.totalDue;
    });
    return total;
  }

  function PaymentComplete() {
    return (
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <CheckCircleIcon
          sx={{ width: "50px", height: "50px", color: "green" }}
        />
        <Typography variant="h6">All Payments Complete</Typography>
        <Typography variant="h5" sx={{ marginTop: "20px" }}>
          Thank you!
        </Typography>
      </Stack>
    );
  }

  function UpcomingPayment() {
    return (
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <Typography variant="h7">Next Payment Due:</Typography>
        <Typography variant="h4">{payDateString}</Typography>
        <Typography variante="caption">{`$${upcomingPaymentTotal()}`}</Typography>
        <Button
          color="secondary"
          onClick={showPaymentModal}
          variant="contained"
        >
          Pay Now
        </Button>
      </Stack>
    );
  }

  function OverduePayment() {
    return (
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <ErrorIcon sx={{ width: "50px", height: "50px", color: "red" }} />
        <Typography variant="h7" sx={{ color: "red", textAlign: "center" }}>
          {overdueSessions.length < 2 ? (
            "You have an overdue payment."
          ) : (
            <Fragment>
              You have {overdueSessions.length} overdue payments.
              <br />
              Your registration{overdueSessions.length > 1 ? "s" : ""} may be
              forfeited!
            </Fragment>
          )}
        </Typography>
        <Typography
          variant="h7"
          sx={{ color: "red", marginTop: "5px" }}
        ></Typography>
        <Button
          color="secondary"
          onClick={showPaymentModal}
          variant="contained"
        >
          Pay Now
        </Button>
      </Stack>
    );
  }

  function TotalPaymentsDue() {
    return (
      <Stack direction="column" sx={{ alignItems: "center" }}>
        <h4 style={{ marginBottom: "5px" }}>Total Payments Due:</h4>$
        {totalDue || 0}
      </Stack>
    );
  }

  function payStatusInfo() {
    if (totalDue <= 0) return <PaymentComplete />;
    else if (nextPaymentDate >= now) return <UpcomingPayment />;
    else if (nextPaymentDate < now) return <OverduePayment />;
  }

  function getSessionsToPay() {
    if (nextPaymentDate >= now) return nextSessionsToPay;
    else if (nextPaymentDate < now) return overdueSessions;
  }

  return (
    <DashboardBox label="Payment Status">
      <div style={{ marginTop: "13px" }}>{payStatusInfo()}</div>
      {modalVisibility &&
        createPortal(
          <Modal open={modalVisibility} onClose={hidePaymentModal}>
            <Box sx={modalStyle}>
              <MakePayment
                chosenSessions={getSessionsToPay()}
                otherSessions={otherSessions}
                campers={campers}
              />
              <Button onClick={hidePaymentModal} sx={{ marginTop: "15px" }}>
                Cancel
              </Button>
            </Box>
          </Modal>,
          document.getElementById("modal-root")
        )}
    </DashboardBox>
  );
};

export default PaymentDueBox;
