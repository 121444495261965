export const initialState = {
  campers: [],
  registrations: [],
  digitalSignature: "",
  totalPrice: 0,
  totalDiscount: 0,
  isRegistrationAdded: false,
};

export const PAY_FULL = "full";
export const PAY_DEPOSIT = "deposit";
export const PAY_DEFAULT = PAY_FULL;
