import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import {
  DEFAULT_PROGRAM_TYPE,
  tabsToArray,
} from "../../store/account/constants";
import TabItem from "./TabItem";
import TabMenuItem from "./TabMenuItem";
import { useNavigate } from "react-router-dom";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import { useState } from "react";
import { authActions } from "../../store/auth/slice";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar } from "@mui/material";
import { useTheme } from "@emotion/react";
import { accountActions } from "../../store/account/slice";
import ToggleSwitch from "../UI/ToggleSwitch";
import { useGetCustomerProgramTypesQuery } from "../../store/account/api";
import { useMediaQuery } from "@mui/material";

const AccountToolbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElPt, setAnchorElPt] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery("(max-width:1110px)");

  const programType = useSelector((state) => state.account.programType);
  const customerId = useSelector((state) => state.auth.customerId);

  const { customerInfo: customer, isSuccessCustomerInfo } = useCustomerInfo();

  const { data: programTypes, isSuccess: isSuccessProgramTypes } =
    useGetCustomerProgramTypesQuery(customerId);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenPtMenu = (event) => {
    setAnchorElPt(event.currentTarget);
  };

  const handleClosePtMenu = () => {
    setAnchorElPt(null);
  };

  function handleChangeProgramType(pType) {
    dispatch(accountActions.setProgramType(pType.name));
    dispatch(accountActions.setYear(pType.year));
    dispatch(accountActions.setProgramTypeChosen(true));
    handleClosePtMenu();
  }

  const onSignoutClick = () => {
    dispatch(accountActions.setProgramTypeChosen(false));
    dispatch(accountActions.setProgramType(DEFAULT_PROGRAM_TYPE));
    dispatch(authActions.signOut());
    navigate("/signin");
  };

  function onEditAccountClick() {
    navigate("/account/edit");
  }

  const settings = [
    { name: "Edit Account", handleClick: onEditAccountClick },
    ...programTypes
      .map((pType) => {
        if (!pType.name.includes(programType)) {
          return {
            name: "View " + pType.name,
            handleClick: () => {
              handleCloseUserMenu();
              handleChangeProgramType(pType);
            },
          };
        }
        return undefined;
      })
      .filter((option) => option !== undefined),
    { name: "Sign Out", handleClick: onSignoutClick },
  ];

  function getLogo() {
    if (programType.includes("C-CATS")) {
      return process.env.PUBLIC_URL + "/assets/logos/CCats-white-V.png";
    } else if (programType.includes("Summer Camp")) {
      return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-white.png";
    } else return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-white.png";
  }

  return (
    <Toolbar
      disableGutters
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 3,
          width: "7%",
          maxHeight: "45px",
        }}
      >
        <img
          style={{
            width: "100%",
            marginRight: "15px",
            objectFit: "scale-down",
          }}
          src={getLogo()}
          alt="Camp Logo"
        />
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none", textColor: "black" },
          }}
        >
          {tabsToArray().map((tab) => (
            <TabMenuItem
              key={tab.title}
              tab={tab}
              handleCloseMenu={handleCloseNavMenu}
            />
          ))}
        </Menu>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          mr: 0,
          width: "15%",
          maxHeight: "45px",
        }}
      >
        <img
          style={{ width: "100%", objectFit: "scale-down" }}
          src={getLogo()}
          alt="Camp Logo"
        />
      </Box>
      <Typography
        variant="h5"
        noWrap
        component="a"
        href=""
        sx={{
          mr: 0,
          display: { xs: "flex", md: "none" },
          flexGrow: 1,
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
        }}
      >
        &nbsp;
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {tabsToArray().map((tab) => (
          <TabItem key={tab.title} tab={tab} />
        ))}
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
              {isSuccessCustomerInfo && customer.firstName && customer.lastName
                ? customer.firstName[0] + customer.lastName[0]
                : null}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting.name} onClick={setting.handleClick}>
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {isSuccessProgramTypes && programTypes.length === 2 && !smallScreen && (
        <Box sx={{ float: "right" }}>
          <ToggleSwitch options={programTypes} style={{ float: "right" }} />
        </Box>
      )}
      {isSuccessProgramTypes && programTypes.length > 2 && !smallScreen && (
        <Box sx={{ float: "right" }}>
          <Tooltip title="Choose program type">
            <IconButton
              size="large"
              aria-label="available camp program types"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenPtMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-program-type"
            anchorEl={anchorElPt}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElPt)}
            onClose={handleClosePtMenu}
          >
            {programTypes.map((pType) => (
              <MenuItem
                key={pType.name}
                onClick={() => handleChangeProgramType(pType)}
              >
                <Typography textAlign="center">{pType.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Toolbar>
  );
};
export default AccountToolbar;
