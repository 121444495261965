import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    tagTypes: ["Customer"],
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getReceipts: build.query({
      query: (customerId) => ({
        url: `get_customer_receipts${customerId ? `/${customerId}` : ""}`,
      }),
    }),
    getCustomerProgramTypes: build.query({
      query: (customerId) => ({
        url: `get_customer_program_types/${customerId}`,
      }),
      transformResponse: (response) => {
        console.log("called api (get program types)");
        return response;
      },
    }),
    getCamperInfo: build.query({
      query: ({ customerId, programType }) => ({
        url: `get_all_campers_info/${customerId}${
          programType ? `/${programType}` : ""
        }`,
      }),
      providesTags: ["Campers"],
      transformResponse: (response) => {
        console.log("called api (camper info)");
        return response.map((camper) => {
          return {
            id: camper.id,
            firstName: camper.first_name,
            lastName: camper.last_name,
            fullName: camper.first_name + " " + camper.last_name,
            birthdate: new Date(camper.birthday).toISOString().slice(0, -1), // @todo this probably needs to be changed
            sex: camper.gender,
            shirtSize: camper.tshirt_size,
            documentInfo: camper.documentInfo,
            isCurrent: camper.isCurrent,
            sessions: camper.sessions,
            pastSessions: camper.pastSessions,
            files: [],
          };
        });
      },
    }),
    getCustomerInfo: build.query({
      query: () => "get_customer_info",
      providesTags: ["Customer"],
      transformResponse: (response) => {
        console.log("called api (customer info)");
        delete response.ok;
        delete response.message;
        return response;
      },
    }),
    updateAccount: build.mutation({
      query: (postData) => {
        console.log("Posting: " + JSON.stringify(postData));
        return {
          url: "update_account",
          method: "POST",
          body: postData,
        };
      },
      invalidatesTags: ["Customer"],
    }),
    updateCamper: build.mutation({
      query: (postData) => {
        console.log("Posting: " + JSON.stringify(postData));
        return {
          url: "update_camper",
          method: "POST",
          body: postData,
        };
      },
      async onQueryStarted(postData, { dispatch, queryFulfilled }) {
        const postResult = dispatch(
          accountApi.util.updateQueryData(
            "getCamperInfo",
            undefined,
            (draft) => {
              Object.assign(draft, postData);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          //undo optimistic changes on mutation error
          postResult.undo();
        }
      },
    }),
    uploadForms: build.mutation({
      query: (postData) => {
        console.log("Posting: " + JSON.stringify(postData));
        console.log(JSON.stringify([...postData.keys()]));
        return {
          url: "upload_forms",
          method: "POST",
          body: postData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => {
        console.log("response: " + JSON.stringify(response));
      },
    }),
    checkEmail: build.query({
      query: (email) => {
        return {
          url: "check_email_uniqueness/" + email,
        };
      },
    }),
    processPayment: build.mutation({
      query: (paymentData) => {
        return {
          url: "process_payment",
          method: "POST",
          body: paymentData,
        };
      },
      invalidatesTags: ["Campers"],
    }),
  }),
});

export const {
  useGetReceiptsQuery,
  useGetCustomerProgramTypesQuery,
  useGetCamperInfoQuery,
  useGetCustomerInfoQuery,
  useUpdateAccountMutation,
  useUpdateCamperMutation,
  useCheckEmailQuery,
  useUploadFormsMutation,
  useProcessPaymentMutation,
} = accountApi;
