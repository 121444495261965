import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import AlertMessage from "./AlertMessage";
import PromptDialog from "./PromptDialog";

import { CssBaseline, Container, Toolbar, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { Fragment, memo, useEffect } from "react";
import { TYPE_CCATS } from "../../store/account/constants";
import BackButton from "./BackButton";

const DefaultLayout = () => {
  const { programType } = useSelector((state) => state.account);
  const { alert } = useSelector((state) => state.ui);
  const smallScreen = useMediaQuery("(max-width:1000px)");
  const summerBG = process.env.PUBLIC_URL + "/assets/img_summer.jpg";
  const ccatsBG = process.env.PUBLIC_URL + "/assets/img_ccats.jpg";

  //update image when program type changes
  useEffect(() => {
    let background = document.getElementById("background_image");
    background.style.display = "block";
    if (programType === TYPE_CCATS) {
      if (!background.src.includes(ccatsBG)) background.src = ccatsBG;
    } else {
      if (!background.src.includes(summerBG)) background.src = summerBG;
    }
  }, [programType]);

  useEffect(() => {
    let background = document.getElementById("background_image");
    if (smallScreen) background.style.display = "none";
    else background.style.display = "block";
  }, [smallScreen]);

  //todo: this is a duplicate function from AccountToolbar.js and CartToolbar.js
  function getLogo() {
    if (programType.includes("C-CATS")) {
      return process.env.PUBLIC_URL + "/assets/logos/CCats-color-V.png";
    } else if (programType.includes("Summer Camp")) {
      return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-color.png";
    } else return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-color.png";
  }

  return (
    <Fragment>
      <Container
        sx={{
          width: smallScreen ? "100%" : "50%",
          marginLeft: 0,
          marginRight: 0,
          height: "100%",
        }}
        disableGutters
      >
        <CssBaseline />
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#F5F7F8",
              height: "100%",
              paddingLeft: { lg: "12%", md: "10%", sm: "5%", xs: "5%" },
              paddingRight: { lg: "12%", md: "10%", sm: "5%", xs: "5%" },
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <BackButton />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                mr: 3,
                maxHeight: "45px",
                marginBottom: "40px",
              }}
            >
              <img
                style={{
                  width: "150px",
                  objectFit: "scale-down",
                  maxHeight: "80px",
                }}
                src={getLogo()}
              />
            </Box>
            <Container maxWidth="md">
              <Outlet />
            </Container>
            <PromptDialog />
            <AlertMessage
              open={alert.open}
              message={alert.message}
              severity={alert.severity}
            />
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default DefaultLayout;
