import { useEffect, useState } from "react";
import TaskChecklistList from "./TaskChecklistList";
import {
  FORM_NEEDED,
  FORM_REJECTED,
} from "../../../../store/account/constants";
import DashboardBox from "../../../UI/DashboardBox";
import { tabs } from "../../../../store/account/constants";

const TaskChecklist = ({ variant, campers, customerInfo }) => {
  const [infoCompletion, setInfoCompletion] = useState(false);
  const [formCompletion, setFormCompletion] = useState(false);
  const [paymentCompletion, setPaymentCompletion] = useState(false);

  const tasks = [
    {
      title: "Update Contact Info",
      incompleteDesc: "Contact info is required",
      completedDesc: "Required information collected",
      completed: infoCompletion,
      url: "account/edit",
    },
    {
      title: "Upload Forms",
      incompleteDesc: "Camp forms required",
      completedDesc: "All forms have been uploaded",
      completed: formCompletion,
      url: "account/" + tabs.documents.url,
    },
    {
      title: "Make Payments",
      incompleteDesc: "Payment required",
      completedDesc: "Payments complete",
      completed: paymentCompletion,
      url: "account/" + tabs.payment.url,
    },
  ];

  // Check if customer has completed all account info
  useEffect(() => {
    for (const property in customerInfo) {
      if (
        !customerInfo[property] ||
        customerInfo[property].toString().trim().length === 0
      ) {
        setInfoCompletion(false);
        return;
      }
    }
    setInfoCompletion(true);
  }, [customerInfo]);

  // Check if customer has uploaded all required forms
  useEffect(() => {
    let continueLoop = true;
    campers?.forEach((camper) => {
      if (camper.isCurrent && continueLoop) {
        camper.documentInfo?.forEach((document) => {
          if (
            document.statusDesc === FORM_NEEDED ||
            document.statusDesc === FORM_REJECTED
          ) {
            setFormCompletion(false);
            continueLoop = false;
            return;
          }
        });
      }
    });
    if (continueLoop) setFormCompletion(true);
  }, [campers]);

  // Check if customer has made all payments
  useEffect(() => {
    let continueLoop = true;
    campers?.forEach((camper) => {
      if (camper.isCurrent && continueLoop) {
        camper.sessions?.forEach((session) => {
          if (session.totalDue > 0) {
            setPaymentCompletion(false);
            continueLoop = false;
            return;
          }
        });
      }
    });
    if (continueLoop) setPaymentCompletion(true);
  }, [campers]);

  return (
    <DashboardBox maxHeight={variant === "list" ? 200 : ""} label="To-Do List">
      <TaskChecklistList tasks={tasks} />
    </DashboardBox>
  );
};

export default TaskChecklist;
