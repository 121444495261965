import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import DashboardBox from "../../../UI/DashboardBox";

const ParentDetailsBox = ({ campers }) => {
  const [detailsLinks, setDetailsLinks] = useState({});

  useEffect(() => {
    let links = {};
    campers &&
      campers.forEach((camper) => {
        camper.sessions.forEach((session) => {
          if (!(session.name in links)) {
            links[session.name] = session.detailsLink;
          }
        });
      });
    setDetailsLinks(links);
  }, [campers]);

  function getLinks() {
    let result = [];
    for (const sessionName in detailsLinks) {
      result.push(
        <ListItem
          key={sessionName}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a href={detailsLinks[sessionName]} target="blank">
            {sessionName}
          </a>
        </ListItem>
      );
    }
    return result;
  }

  return (
    <DashboardBox maxHeight={200} label="Parent Details">
      <List>{getLinks()}</List>
    </DashboardBox>
  );
};

export default ParentDetailsBox;
