import { createSlice } from "@reduxjs/toolkit";

import { cartActions } from "../cart/slice";
import { regActions } from "../register/slice";
import {
  initialState,
  ALERT_ERROR,
  ALERT_SUCCESS,
  ALERT_INFO,
  ALERT_WARNING,
} from "./constants";

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setActiveRegisterStep(state, { payload: step }) {
      state.activeRegisterStep = step;
    },
    setMobileOpen(state, { payload: open }) {
      state.mobileOpen = open;
    },
    setAlertError(state, { payload: message = "Something went wrong!" }) {
      state.alert = { ...ALERT_ERROR, message: message };
    },
    setAlertWarning(state, { payload: message = "Warning!" }) {
      state.alert = { ...ALERT_WARNING, message: message };
    },
    setAlertSuccess(state, { payload: message = "Success!" }) {
      state.alert = { ...ALERT_SUCCESS, message: message };
    },
    setAlertInfo(state, { payload: message = "Info!" }) {
      state.alert = { ...ALERT_INFO, message: message };
    },
    clearAlert(state) {
      state.alert = initialState.alert;
    },
    warnDeleteCamper(state, { payload: camperKey }) {
      state.promptDialog = {
        ...deleteCamperWarning,
        open: true,
        data: camperKey,
      };
    },
    warnDeleteRegistration(state, { payload: regKey }) {
      state.promptDialog = {
        ...deleteRegistrationWarning,
        open: true,
        data: regKey,
      };
    },
    closeWarningDialog(state) {
      state.promptDialog = { ...initialState.promptDialog, open: false };
    },
    setBackAction(state, { payload: action }) {
      state.backAction = action;
    },
    setBackText(state, { payload: text }) {
      state.backText = text;
    },
  },
});

export const uiActions = uiSlice.actions;

const deleteCamperWarning = {
  title: "Delete Camper?",
  message:
    "Are you sure you want to remove this camper's registrations from the cart?",
  onNo: (dispatch) => {
    dispatch(uiActions.closeWarningDialog());
  },
  onYes: (dispatch, data, programType) => {
    dispatch(cartActions.deleteCamper(data));
    dispatch(cartActions.updatePayAmounts(programType));
    dispatch(cartActions.updateRemainingBalances());
    dispatch(uiActions.closeWarningDialog());
  },
};

const deleteRegistrationWarning = {
  title: "Delete Registration?",
  message: "Are you sure you want to remove this registration from the cart?",
  onNo: (dispatch) => {
    dispatch(uiActions.closeWarningDialog());
  },
  onYes: (dispatch, data, programType) => {
    dispatch(cartActions.deleteRegistration(data));
    dispatch(cartActions.updatePayAmounts(programType));
    dispatch(cartActions.updateRemainingBalances());
    dispatch(uiActions.closeWarningDialog());
    dispatch(regActions.setupChooseRegisterAction());
  },
};

export default uiSlice.reducer;
