import { Box } from "@mui/material";
import BoxHeader from "../Account/Dashboard/InfoBoxes/BoxHeader";

const DashboardBox = (props) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "3px",
        padding: "5px",
        width: "100%",
        minHeight: "200px",
        maxHeight: props.maxHeight || "100%",
        background: "#f2f2f2",
        overflow: "auto",
      }}
    >
      <BoxHeader text={props.label?.toUpperCase()} />
      {props.children}
    </Box>
  );
};

export default DashboardBox;
