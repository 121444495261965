import { useState } from "react";
import PropTypes from "prop-types";

import ScrollableDialog from "../UI/ScrollableDialog";

import { Typography } from "@mui/material";
import { Box } from "@mui/material";

// displays info for one part of the legal terms
const Permission = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  // pop out dialog for viewing permission content
  const openDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Typography fontStyle="italic" fontWeight={700}>
        {title}
      </Typography>
      <Box
        onClick={openDialog}
        height="200px"
        overflow="scroll"
        p={1}
        border="1px solid lightgray"
        borderRadius="4px"
        sx={{
          cursor: "pointer",
          whiteSpace: "pre-wrap",
          fontSize: "0.8rem",
          backgroundColor: "white",
        }}
      >
        {content}
      </Box>
      <Box p={1} /> {/* space between permissions */}
      <ScrollableDialog
        title={title}
        content={content}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

Permission.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Permission;
