import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ACCOUNT_BASE_URL } from "../../store/account/constants";

import { authActions } from "../../store/auth/slice";
import { accountActions } from "../../store/account/slice";
import LoadingScreen from "../UI/LoadingScreen";

const Masquerade = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [programType, setProgramType] = useState("");

  useEffect(() => {
    try {
      if (token) {
        dispatch(authActions.setTokenValues(token));
        dispatch(accountActions.setProgramType(programType));
        dispatch(authActions.setMasquerading(true));
        navigate(ACCOUNT_BASE_URL);
      } else {
        const newToken = searchParams.get("token");
        const programType = searchParams.get("programtype");
        setProgramType(programType);
        setToken(newToken);
        dispatch(authActions.setMasquerading(true));
      }
    } catch (e) {
      // TODO handle errors
      console.error(e);
    }
  }, [token]);

  return <LoadingScreen />;
};

export default Masquerade;
