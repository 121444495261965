import PropTypes from "prop-types";

import { memo } from "react";
import { FormHelperText, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const TextInput = ({
  type,
  name,
  label,
  value,
  onChange,
  required,
  error,
  disabled,
  id,
  maxLength,
  autoComplete,
  InputProps,
  variant = "outlined",
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        variant={variant}
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        error={!!error}
        disabled={disabled}
        id={id}
        inputProps={maxLength ? { maxLength: +maxLength } : {}}
        InputProps={InputProps}
        autoComplete={autoComplete}
        sx={{ backgroundColor: "white" }}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  variant: PropTypes.string,
};

export default memo(TextInput);
