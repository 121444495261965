import { useEffect, useRef } from "react";

import { Avatar } from "@mui/material";
import { Grow } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Popper } from "@mui/material";

export default function AvatarMenu({ children, isOpen, setIsOpen }) {
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  return (
    <Box sx={{ cursor: "pointer" }}>
      <Avatar
        ref={anchorRef}
        onClick={handleToggle}
        sx={{ width: 32, height: 32 }}
      />
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
