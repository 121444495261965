import { useDispatch } from "react-redux";
import Permission from "./Permission";
import { usePermissions } from "../../store/register/hooks";
import Loader from "../UI/Loader";
import { Divider, Typography } from "@mui/material";
import TextInput from "../UI/TextInput";
import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { cartActions } from "../../store/cart/slice";
import { regActions } from "../../store/register/slice";
import { uiActions } from "../../store/ui/slice";

// container for displaying legal terms
const Permissions = () => {
  const dispatch = useDispatch();
  const [digitalSignature, setDigitalSignature] = useState({
    value: "",
    error: "",
  });

  const { permissions, isSuccessPermissions } = usePermissions();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (digitalSignature.value.length === 0) {
      setDigitalSignature({
        ...digitalSignature,
        error: "Digital signature is required",
      });
    } else {
      dispatch(cartActions.setDigitalSignature(digitalSignature.value));
      dispatch(regActions.setupChooseRegisterAction());
    }
  };

  const handleChange = (e) => {
    setDigitalSignature({ error: "", value: e.target.value });
  };

  const renderPermissions = () => {
    if (isSuccessPermissions) {
      return permissions.map((permission) => (
        <Permission
          key={permission.id}
          title={permission.title}
          content={permission.content}
        />
      ));
    } else {
      return <Loader />;
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit}
      spacing={1}
      sx={{ width: "100%" }}
    >
      <Divider variant="middle" style={{ width: "100%" }}>
        <Typography variant="h5">Permissions</Typography>
      </Divider>
      <Box p={1}>
        <Typography variant="body1" textAlign="center">
          Please review the following permissions before continuing.
        </Typography>
      </Box>
      {renderPermissions()}
      <Typography variant="caption" textAlign="center">
        By entering your signature below, you acknowledge the terms above.
      </Typography>
      <TextInput
        name="digitalSignature"
        label="Digital Signature"
        value={digitalSignature.value}
        onChange={handleChange}
        error={digitalSignature.error}
      />
      <Box p={1} /> {/* easy spacing */}
      <Button type="submit" variant="contained" fullWidth>
        Acknowledge
      </Button>
    </Stack>
  );
};

export default Permissions;
