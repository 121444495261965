import { useNavigate } from "react-router-dom";
import TextInput from "../UI/TextInput";
import { Button, Typography } from "@mui/material";
import PageHeading from "../UI/PageHeading";

const SignInForm = ({ form, errors, onChange }) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeading text="Sign In" />
      <Typography sx={{ marginBottom: "16px" }}>
        To begin registration, sign in to your account or click “create account”
      </Typography>
      <TextInput
        type="text"
        name="email"
        label="Email"
        value={form.email}
        onChange={onChange}
        error={errors.email}
        autoComplete="email"
      />
      <TextInput
        type="password"
        name="password"
        label="Password"
        value={form.password}
        onChange={onChange}
        error={errors.password}
        autoComplete="current-password"
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ height: "55px" }}
        fullWidth
      >
        Sign in
      </Button>
      <div style={{ width: "100%" }}>
        <Typography variant="body2" style={{ float: "left" }}>
          <Button
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot Password?
          </Button>
        </Typography>
        <Typography variant="body2" style={{ float: "right" }}>
          <Button
            onClick={() => {
              navigate("/signup");
            }}
          >
            Create Account
          </Button>
        </Typography>
      </div>
    </>
  );
};

export default SignInForm;
