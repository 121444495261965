import { Button } from "@mui/material";
import { Fragment } from "react";

const ChooseFilesButton = ({ camperId, files, addFile }) => {
  function clickFileInput() {
    document.getElementById(`chooseFiles[${camperId}]`).click();
  }

  function fileChangeHandler(event) {
    Array.from(event.target.files).forEach((file) => {
      if (!isDuplicateFile(file.name)) {
        addFile(camperId, file);
      }
    });
  }

  function isDuplicateFile(fileName) {
    return (
      files?.some((existingFile) => existingFile.name === fileName) || false
    );
  }

  return (
    <Fragment>
      <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
        <input
          id={`chooseFiles[${camperId}]`}
          type="file"
          accept="application/pdf"
          onChange={fileChangeHandler}
          multiple
        />
      </div>
      <Button color="secondary" variant="contained" onClick={clickFileInput}>
        Choose Files
      </Button>
    </Fragment>
  );
};

export default ChooseFilesButton;
