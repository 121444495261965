import { useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSubmitBugReportMutation } from "../../store/error/api";
import { errorActions } from "../../store/error/slice";
import BugReportForm from "./BugReportForm";

const BugReportDialog = ({ open, closeDialog, onSubmit, inErrorBoundary }) => {
  const error = useSelector((state) => state.error.error);
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery("(max-width:750px)");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallScreen ? "90%" : "740px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
  };

  const [
    submitBugReport,
    {
      data: bugReportData,
      isLoading: isSubmittingBugReport,
      isSuccess: isSuccessBugReport,
      error: bugReportError,
      isError: isErrorBugReport,
    },
  ] = useSubmitBugReportMutation();

  function submitReport(description) {
    let stackTrace = inErrorBoundary
      ? "(From error boundary)\n" + error
      : error;
    submitBugReport({ isManual: true, stackTrace, description });
    closeDialog();
    onSubmit && onSubmit();
  }

  return createPortal(
    <Modal open={open} onClose={closeDialog}>
      <Box sx={modalStyle}>
        <BugReportForm onSubmit={submitReport} onCancel={closeDialog} />
      </Box>
    </Modal>,
    document.getElementById("modal-root")
  );
};

export default BugReportDialog;
