export const initialState = {
  activeRegisterStep: 0,
  mobileOpen: false,
  alert: { open: false, message: "", severity: "info" },
  promptDialog: {
    open: false,
    title: "",
    message: "",
    onNo: () => {},
    onYes: () => {},
    data: null,
  },
  backAction: () => {
    console.log("back");
  },
  backText: "BACK",
};

export const DEFAULT_LAYOUT = 0;
export const CART_LAYOUT = 1;
export const ACCOUNT_LAYOUT = 2;

export const REGISTER_STEPS = ["Login", "Register", "Checkout"];
export const AUTH_STEP = 0;
export const REGISTER_STEP = 1;
export const CHECKOUT_STEP = 2;

export const CONTACT_STEP = 0;
export const ADDRESS_STEP = 1;
export const PARTICIPANT_STEP = 2;

export const MSG_SUBMITTING_CART =
  "Processing payment and submitting registrations from cart.";

export const MSG_EMAIL_TAKEN =
  "That email address is taken. Please try signing in or signing up with a different email address.";

export const MSG_ERR_REFRESH_SESSION = "Failed to refresh session.";

export const MSG_AUTH_REQUIRED = "Please login to continue.";

export const MSG_NEXT_STEPS_UPDATED =
  "Account information updated successfully!";

export const MSG_ACCOUNT_CREATED = "New account created successfully!";

export const MSG_REGISTERED = "Thank you for registering!";

export const MSG_PW_RESET =
  "Password reset successfully! Please check your email.";

export const MSG_SESSION_TIMED_OUT =
  "Your session timed out due to inactivity.";

export const ALERT_ERROR = {
  severity: "error",
  open: true,
};

export const ALERT_WARNING = {
  severity: "warning",
  open: true,
};

export const ALERT_SUCCESS = {
  severity: "success",
  open: true,
};

export const ALERT_INFO = {
  severity: "info",
  open: true,
};
