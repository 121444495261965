import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TaskChecklistList = ({ tasks }) => {
  const navigate = useNavigate();

  return (
    <List sx={{ width: "100%", maxWidth: 360, margin: "0px" }} dense>
      {tasks.map((task) => {
        return (
          <ListItem key={task.title} sx={{ paddingLeft: "0px" }}>
            <ListItemButton onClick={() => navigate("/" + task.url)}>
              <ListItemIcon>
                {task.completed ? (
                  <CheckIcon sx={{ color: "green" }} />
                ) : (
                  <CloseIcon sx={{ color: "red" }} />
                )}
              </ListItemIcon>
              <ListItemText primary={task.title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default TaskChecklistList;
