import { useEffect, useState } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";
import * as sdkUtils from "smartystreets-javascript-sdk-utils";

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usStreet.Lookup;

const useSmarty = () => {
  const [client, setClient] = useState(null);

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    let key = process.env.REACT_APP_SMARTY_KEY;
    const credentials = new SmartyCore.SharedCredentials(key);
    let clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
      "us-standard-cloud",
    ]);
    setClient(clientBuilder.buildUsStreetApiClient());
  }, []);

  const lookupAddress = (data) => {
    reset();
    let lookup = new Lookup();
    lookup.street = data.street;
    lookup.city = data.city;
    lookup.state = data.state;
    lookup.zip = data.zip;

    if (lookup.street) {
      client
        .send(lookup)
        .then((response) => handleLookupResponse(response))
        .catch((e) => setError(e.error));
    } else {
      setError("A street address is required.");
    }
  };

  const handleLookupResponse = (response) => {
    const lookup = response.lookups[0];
    const isValid = sdkUtils.isValid(lookup);
    const isAmbiguous = sdkUtils.isAmbiguous(lookup);
    const isMissingSecondary = sdkUtils.isMissingSecondary(lookup);

    if (!isValid) {
      handleError("The address is invalid");
    } else if (isAmbiguous) {
      handleError("The address is ambiguous");
    } else if (isMissingSecondary) {
      handleError("The address is missing a secondary number");
    } else if (isValid) {
      const candidate = lookup.result[0];
      handleSuccess(candidate);
    }
  };

  const handleSuccess = (data) => {
    setData({
      street: data.deliveryLine1,
      city: data.components.cityName,
      state: data.components.state,
      zip: `${data.components.zipCode}-${data.components.plus4Code}`,
    });
    setIsSuccess(true);
  };

  const handleError = (error) => {
    setIsError(true);
    setError(error);
  };

  const reset = () => {
    setIsError(false);
    setError("");
    setIsSuccess(false);
    setData(null);
  };

  return {
    isError,
    error,
    isSuccess,
    data,
    lookupAddress,
  };
};

export default useSmarty;
