import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth/slice";
import { useRefreshToken } from "../../store/auth/hooks";
import { useEffect } from "react";
import { uiActions } from "../../store/ui/slice";
import {
  MSG_ERR_REFRESH_SESSION,
  MSG_SESSION_TIMED_OUT,
} from "../../store/ui/constants";
import CountdownTimer from "../UI/CountdownTimer";
import jwtDecode from "jwt-decode";
import { createPortal } from "react-dom";
import AlertDialog from "../UI/AlertDialog";
import { useNavigate } from "react-router-dom";
import { accountActions } from "../../store/account/slice";
import { DEFAULT_PROGRAM_TYPE } from "../../store/account/constants";

// manages jwt expiration countdown, submits refresh token request when expired
const TokenTimer = () => {
  const dispatch = useDispatch();
  const { tokenExpiration } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const {
    submitRefreshToken,
    refreshTokenData,
    isErrorRefreshToken,
    isSuccessRefreshToken,
    refreshTokenError,
  } = useRefreshToken();

  // show error alert when refresh session fails
  useEffect(() => {
    if (isErrorRefreshToken) {
      dispatch(uiActions.setAlertError(MSG_ERR_REFRESH_SESSION));
      localStorage.setItem("tokenError", JSON.stringify(refreshTokenError));
    }
  }, [isErrorRefreshToken, dispatch]);

  // store refreshed token in local storage and and update expiration time in redux
  useEffect(() => {
    if (isSuccessRefreshToken) {
      dispatch(authActions.setTokenValues(refreshTokenData));
    }
  }, [refreshTokenData, isSuccessRefreshToken, dispatch]);

  // logs token expiration timer in console
  function renderer({ hours, minutes, seconds, zeroPad }) {
    //console.log(hours + ":" + zeroPad(minutes) + ":" + zeroPad(seconds));
    // document.title = `YLI Registrations (${
    //   hours > 0 ? hours + ":" : ""
    // }${zeroPad(minutes)}:${zeroPad(seconds)})`;
    if (hours === 0 && minutes < 15) {
      return createPortal(
        <AlertDialog
          title="Session Expiring Soon"
          text={`Your session will expire in ${zeroPad(minutes)}:${zeroPad(
            seconds
          )}`}
          firstOptionText="Sign Out"
          secondOptionText="Continue"
          isOpen={true}
          onClose={handleClose}
          onFirstOption={signOut}
          onSecondOption={submitRefreshToken}
        />,
        document.getElementById("modal-root")
      );
    }

    return null;
  }

  // redirect to signin when session expiration reached
  function redirect() {
    document.title = "YLI Registrations";
    // only navigate if the expiration hasn't been cleared (meaning the user didn't click logout)
    dispatch(uiActions.setAlertInfo(MSG_SESSION_TIMED_OUT));
    dispatch(authActions.expiredRedirect());
    navigate("/signin?status=expired", { replace: true });
  }

  // delete stored token and reset auth slice
  // TODO should we reset all slices?
  const signOut = () => {
    localStorage.removeItem("token");
    dispatch(accountActions.setProgramTypeChosen(false));
    dispatch(accountActions.setProgramType(DEFAULT_PROGRAM_TYPE));
    dispatch(authActions.signOut());
    navigate("/signin");
    //todo: invalidate token on server
  };

  // close session timeout modal
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
  };

  // get the date to count down to (prevents negative values if token expires in less than one minute)
  function getDate() {
    const token = localStorage.getItem("token");
    const tokenObject = jwtDecode(token);
    return +tokenObject.exp * 1000;
  }

  return tokenExpiration ? (
    <CountdownTimer
      date={getDate()}
      onComplete={redirect}
      renderer={renderer}
    />
  ) : null;
};

export default TokenTimer;
