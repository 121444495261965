import DashboardTab from "./Dashboard/DashboardTab";
import RegisterTab from "./Register/RegisterTab";
import DocumentsTab from "./Documents/DocumentsTab";
import PaymentTab from "./Payment/PaymentTab";
import ReceiptsTab from "./Receipts/ReceiptsTab";
import { accountActions } from "../../store/account/slice";
import { tabs } from "../../store/account/constants";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EditAccountPage from "./Edit/EditAccountPage";
import ChangePassword from "../Authentication/ChangePassword";

const AccountPage = (props) => {
  const currentTab = useSelector((state) => state.account.tab);
  const needsPasswordChange = useSelector(
    (state) => state.auth.needsPasswordChange
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.tab !== undefined) dispatch(accountActions.changeTab(props.tab));
  }, [dispatch, props.tab]);

  if (needsPasswordChange) {
    return <ChangePassword forSecurity={true} />;
  }

  function tabContent() {
    switch (currentTab.title) {
      case tabs.dashboard.title:
        return <DashboardTab />;
      //case tabs.register.title:
      //return <RegisterTab />;
      case tabs.documents.title:
        return <DocumentsTab />;
      case tabs.payment.title:
        return <PaymentTab />;
      case tabs.receipts.title:
        return <ReceiptsTab />;
      default:
        return <DashboardTab />;
    }
  }

  return tabContent();
};

export default AccountPage;
