import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getParticipantDocuments = createAsyncThunk(
  "account/getParticipantDocuments",
  async ({ participantId, programType }) => {
    const token = localStorage.getItem("token");
    await axios
      .get(
        `${apiUrl}get_documents_for_participant/${participantId}/${
          programType ? programType : ""
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      )
      .then((response) => {
        // found this method on the internet. seems a bit hacky.
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
);

export const downloadSingleForm = createAsyncThunk(
  "account/downloadSingleForm",
  async ({ participantId, programType, formId }) => {
    const token = localStorage.getItem("token");
    await axios
      .get(
        `${apiUrl}get_single_document/${participantId}/${programType}/${formId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      )
      .then((response) => {
        // found this method on the internet. seems a bit hacky.
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "camp_form.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
);

export const uploadForms = createAsyncThunk(
  "account/uploadForms",
  async ({ formData }) => {
    const token = localStorage.getItem("token");
    await axios
      .post(`${apiUrl}upload_forms`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => console.log(resp.data));
  }
);

export const thunkReducers = {
  [getParticipantDocuments.rejected]: ({ error }) => {
    console.error(error);
  },
  [downloadSingleForm.rejected]: ({ error }) => {
    console.error(error);
  },
  [uploadForms.rejected]: ({ error }) => {
    console.error(error);
  },
  [uploadForms.fulfilled]: (state, { payload }) => {
    state.shouldRefetch = true;
  },
};
