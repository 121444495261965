import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { AppTitle } from "../../styles";

import { Badge, IconButton } from "@mui/material";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";

import { useEffect, useState } from "react";
import { cartActions } from "../../store/cart/slice";
import "./animate.css";
import AvatarMenu from "../UI/AvatarMenu";
import { Toolbar } from "@mui/material";
import { MenuItem } from "@mui/material";
import { MenuList } from "@mui/material";
import { Stack } from "@mui/material";
import { authActions } from "../../store/auth/slice";
import { useNavigate } from "react-router-dom";
import { accountActions } from "../../store/account/slice";
import { DEFAULT_PROGRAM_TYPE } from "../../store/account/constants";
import { Box } from "@mui/system";

// app toolbar containing title and mobile view's cart badge for opening the cart drawer
const CartToolbar = ({ onDrawerToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { registrations, isRegistrationAdded } = useSelector(
    (state) => state.cart
  );
  const numRegistrations = registrations.length;
  const title = process.env.REACT_APP_TITLE;
  const { programType } = useSelector((state) => state.account);

  useEffect(() => {
    if (isRegistrationAdded) {
      setTimeout(() => {
        dispatch(cartActions.clearIsRegistrationAdded());
      }, 500);
    }
  }, [isRegistrationAdded]);

  const goToDashboard = () => {
    navigate("/account/dashboard");
  };

  const handleSignout = () => {
    dispatch(accountActions.setProgramTypeChosen(false));
    dispatch(accountActions.setProgramType(DEFAULT_PROGRAM_TYPE));
    dispatch(authActions.signOut());
    navigate("/signin");
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsMenuOpen(false);
    } else if (event.key === "Escape") {
      setIsMenuOpen(false);
    }
  };

  function getLogo() {
    if (programType.includes("C-CATS")) {
      return process.env.PUBLIC_URL + "/assets/logos/CCats-white-V.png";
    } else if (programType.includes("Summer Camp")) {
      return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-white.png";
    } else return process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-white.png";
  }

  return (
    <Toolbar sx={{ flexDirection: "row", justifyContent: "space-between" }}>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            mr: 3,
            width: "7%",
            maxHeight: "45px",
          }}
        >
          <img
            style={{
              width: "100%",
              marginRight: "15px",
              objectFit: "scale-down",
            }}
            src={getLogo()}
          />
        </Box>
        <AppTitle variant="h6" noWrap>
          {title}
        </AppTitle>
      </Stack>
      <Stack direction="row" spacing={1}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={onDrawerToggle}
          sx={{ display: { sm: "none" }, mr: 0 }}
        >
          <Badge
            className={`${isRegistrationAdded ? "animate" : ""}`}
            badgeContent={numRegistrations}
            color="error"
          >
            <ShoppingCartTwoToneIcon />
          </Badge>
        </IconButton>
        <AvatarMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen}>
          <MenuList autoFocusItem={isMenuOpen} onKeyDown={handleListKeyDown}>
            <MenuItem onClick={goToDashboard}>Account Dashboard</MenuItem>
            <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
          </MenuList>
        </AvatarMenu>
      </Stack>
    </Toolbar>
  );
};

CartToolbar.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default CartToolbar;
