import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { regActions } from "../../store/register/slice";
import { uiActions } from "../../store/ui/slice";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function RegistrationItemMenu({ camper, registration }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleEditRegistration = (event) => {
    const data = {
      camper,
      registration,
    };

    navigate("/");
    dispatch(regActions.setupEditRegistration(data));
    dispatch(uiActions.setMobileOpen(false));
    handleClose(event);
  };

  const handleDeleteRegistration = (event) => {
    dispatch(uiActions.warnDeleteRegistration(registration.key));
    dispatch(uiActions.setMobileOpen(false));
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 1201 }} // camper card's zIndex=1200
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper elevation={12}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  disablePadding
                >
                  <MenuItem
                    onClick={handleEditRegistration}
                    sx={{ p: 2, borderBottom: "1px inset lightgray" }}
                  >
                    <EditIcon sx={{ mr: 1 }} />
                    <Typography>Edit Registration</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleDeleteRegistration} sx={{ p: 2 }}>
                    <DeleteForeverIcon sx={{ mr: 1 }} />
                    <Typography>Delete Registration</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
