import { Card, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";

const AddButton = (props) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: "100%",
        textAlign: "left",
        bgcolor: "white",
        borderRadius: 0,
        paddingLeft: "1rem",
        height: "70px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <Stack direction="row">
        <AddIcon
          sx={{
            padding: 0,
            marginRight: "4px",
            width: "20px",
            height: "20px",
            color: theme.palette.primary.main,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          {props.children}
        </Typography>
      </Stack>
    </Card>
  );
};

export default AddButton;
