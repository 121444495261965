import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { accountActions } from "../../store/account/slice";
import "./tabitem.css";
import Button from "@mui/material/Button";
import * as React from "react";
import { ACCOUNT_BASE_URL } from "../../store/account/constants";
import { useTheme } from "@emotion/react";

const TabItem = ({ tab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tab: currTab } = useSelector((state) => state.account);
  const theme = useTheme();

  function tabChangeHandler() {
    dispatch(accountActions.changeTab(tab));
    navigate(ACCOUNT_BASE_URL + tab.url);
  }

  return (
    <Button
      key={tab.title}
      onClick={tabChangeHandler}
      sx={{
        my: 2,
        color: "white",
        display: "block",
        margin: "0px",
        bgcolor:
          currTab.title === tab.title ? theme.palette.selectedTab.main : null,
      }}
    >
      {tab.title}
    </Button>
  );
};

export default TabItem;
