import { Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PendingIcon from "@mui/icons-material/Pending";
export const ACTIVE_STATUS = 0;
export const CANCELLED_STATUS = 1;
export const TRANSFERRED_STATUS = 2;

export const ACCOUNT_BASE_URL = "/account/";

export const EDIT_CAMPER_URL = ACCOUNT_BASE_URL + "edit_camper?id=";
export const CAMPER_PAYMENT_URL = ACCOUNT_BASE_URL + "payment?camper=";
export const CAMPER_REG_URL = "/register?camper=";

export const FORM_NEEDED = "Form Needed";
export const FORM_PENDING = "Pending";
export const FORM_APPROVED = "Approved";
export const FORM_REJECTED = "Rejected";

export const TYPE_SUMMER_CAMP = "Summer Camp";
export const TYPE_CCATS = "C-CATS";
export const DEFAULT_PROGRAM_TYPE = TYPE_SUMMER_CAMP;

export const statusColors = {
  [FORM_NEEDED]: "#3498DB",
  [FORM_PENDING]: "#F4D03F",
  [FORM_REJECTED]: "#E74C3C",
  [FORM_APPROVED]: "#28B463",
};

export const statusIcons = {
  [FORM_NEEDED]: (
    <Avatar sx={{ bgcolor: statusColors[FORM_NEEDED], width: 20, height: 20 }}>
      &nbsp;
    </Avatar>
  ),
  [FORM_PENDING]: <PendingIcon sx={{ color: statusColors[FORM_PENDING] }} />,
  [FORM_REJECTED]: <CloseIcon sx={{ color: statusColors[FORM_REJECTED] }} />,
  [FORM_APPROVED]: <CheckIcon sx={{ color: statusColors[FORM_APPROVED] }} />,
};

export const tabs = {
  dashboard: {
    title: "Dashboard",
    url: "dashboard",
  },
  documents: {
    title: "Forms",
    url: "forms",
  },
  receipts: {
    title: "Receipts",
    url: "receipts",
  },
  payment: {
    title: "Make a Payment",
    url: "payment",
  },
  // editAccount: {
  //   title: "Edit Account",
  //   url: "edit",
  // },
};

export function tabsToArray() {
  return Object.values(tabs);
}

export const initialState = {
  tab: tabs.dashboard,
  programType: DEFAULT_PROGRAM_TYPE,
  programTypeChosen: false,
};

export const EDIT_ACCOUNT_FORM = {
  email: "",
  firstName: "",
  lastName: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  cell: "",
  secondaryFirst: "",
  secondaryLast: "",
  secondaryCell: "",
  emergencyFirst: "",
  emergencyLast: "",
  emergencyCell: "",
};
