import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Stack } from "@mui/material";
import { Container } from "@mui/material";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { updatePasswordValidations } from "../../helpers/validations";
import useStateValidation from "../../hooks/useStateValidation";
import { useUpdatePassword } from "../../store/auth/hooks";
import { authActions } from "../../store/auth/slice";
import { uiActions } from "../../store/ui/slice";
import TextInput from "../UI/TextInput";
import Loader from "../UI/Loader";

export default function ChangePassword({ forSecurity }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({ password: "", confirmPassword: "" });
  const [errors, setErrors] = useState({ password: "", confirmPassword: "" });

  const validateUpdatePassword = useStateValidation(
    updatePasswordValidations,
    setErrors
  );
  const {
    submitUpdatePassword,
    updatePasswordData,
    updatePasswordError,
    isLoadingUpdatePassword,
    isErrorUpdatePassword,
    isSuccessUpdatePassword,
  } = useUpdatePassword();

  useEffect(() => {
    if (isSuccessUpdatePassword) {
      dispatch(uiActions.setAlertSuccess(updatePasswordData));
      dispatch(authActions.clearWantsPasswordChange());
      dispatch(authActions.clearNeedsPasswordChange());
    }
  }, [isSuccessUpdatePassword]);

  useEffect(() => {
    if (isErrorUpdatePassword) {
      dispatch(uiActions.setAlertError(updatePasswordError));
    }
  }, [isErrorUpdatePassword, updatePasswordError]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { password, confirmPassword } = form;

    if (password !== confirmPassword) {
      setErrors({
        password: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
    }
    const { isValid } = validateUpdatePassword(form);
    if (isValid) {
      submitUpdatePassword({ password, confirmPassword });
    }
  };

  if (isLoadingUpdatePassword) {
    return <Loader />;
  }

  return (
    <Container maxWidth="xs">
      <Stack component="form" onSubmit={onSubmit} spacing={2}>
        <Typography variant="h4" textAlign="center">
          Change Password
        </Typography>
        {forSecurity && (
          <Typography variant="body1" textAlign="center">
            Your current password does not meet our current security standards
          </Typography>
        )}
        <Typography variant="body2" textAlign="center">
          Password must be at least 10 characters, contain one uppercase letter,
          one lowercase letter, one number, and one special character.
        </Typography>
        <TextInput
          type="password"
          name="password"
          label="Password"
          value={form.password}
          onChange={onChange}
          error={errors.password}
          autoComplete="new-password"
        />
        <TextInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          value={form.confirmPassword}
          onChange={onChange}
          error={errors.confirmPassword}
          autoComplete="new-password"
        />
        <Button type="submit" variant="contained" color="primary">
          Update
        </Button>
      </Stack>
    </Container>
  );
}
