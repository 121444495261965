import { Typography } from "@mui/material";
import { Fragment } from "react";

const PayeezyInput = (props) => {
  return (
    <Fragment>
      <Typography htmlFor={props.id}>{props.label}</Typography>
      <div className="form-controls payment-fields disabled" {...props} />
    </Fragment>
  );
};

export default PayeezyInput;
