import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { CardMedia } from "@mui/material";

const RegistrationRow = ({ session, past }) => {
  const imgSrc = session.logoSrc
    ? `${process.env.REACT_APP_DOMAIN}/${session.logoSrc}`
    : `${process.env.REACT_APP_DOMAIN}/images/camps/logos/YLI-CAMPS-color.png`;

  function isOverdue() {
    const today = new Date().getTime();
    //add 24 hours to due date because it's at midnight on the start of the day
    const payDate = new Date(session.paymentDueDate).getTime() + 86399000;
    return session.totalDue > 0 && today > payDate;
  }

  return (
    <Fragment>
      <TableCell>
        <Stack direction="row">
          <CardMedia
            component="img"
            alt="camp logo"
            src={imgSrc}
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              width: "32px",
              height: "32px",
              marginRight: "16px",
            }}
          />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ marginTop: "auto" }}
          >
            <Stack direction="column" justifyContent="center">
              <Box>{session.name + ": " + session.num}</Box>
              <Box>
                {!past ? session.dates : `${session.dates} ${session.year}`}
              </Box>
              {isOverdue() && <Box sx={{ color: "red" }}>Payment Overdue</Box>}
            </Stack>
          </Stack>
        </Stack>
      </TableCell>
      {!past && <TableCell align="right">{`$${session.totalDue}`}</TableCell>}
    </Fragment>
  );
};

export default RegistrationRow;
