import { memo } from "react";
import PropTypes from "prop-types";

import RegistrationItemMenu from "./RegistrationItemMenu";
import { CardHeader } from "@mui/material";
import { Divider } from "@mui/material";

const logoProperties = { width: "32px", height: "32px" };

// lists the registrations for a camper within the cart
const RegistrationItem = ({ camper, registration }) => {
  const imgSrc = registration.logoSrc
    ? `${process.env.REACT_APP_DOMAIN}/${registration.logoSrc}`
    : `${process.env.REACT_APP_DOMAIN}/images/camps/logos/YLI-CAMPS-color.png`;

  return (
    <>
      <Divider />
      <CardHeader
        avatar={
          <img alt="camp logo" src={imgSrc} style={{ ...logoProperties }} />
        }
        action={
          <RegistrationItemMenu camper={camper} registration={registration} />
        }
        title={registration.name}
        subheader={registration.dates}
        sx={{
          padding: "16px 8px",
          cursor: "default",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </>
  );
};

RegistrationItem.propTypes = {
  camper: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
};

export default memo(RegistrationItem);
