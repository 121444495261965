import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import authReducer from "./auth/slice";
import registerReducer from "./register/slice";
import cartReducer from "./cart/slice";
import uiReducer from "./ui/slice";
import accountReducer from "./account/slice";
import errorReducer from "./error/slice";
import { registerApi } from "./register/api";
import { accountApi } from "./account/api";
import { authApi } from "./auth/api";
import { cartApi } from "./cart/api";
import { errorApi } from "./error/api";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "account",
    "auth",
    "register",
    "cart",
    "ui",
    registerApi.reducerPath,
    cartApi.reducerPath,
    accountApi.reducerPath,
    authApi.reducerPath,
  ],
};

const authPersistConfig = {
  key: "auth",
  storage,
  blacklist: ["form", "errors", "customerId", "wantsPasswordChange"],
};

const registerPersistConfig = {
  key: "register",
  storage,
  blacklist: [
    "form",
    "errors",
    "disabled",
    "programs",
    "sessions",
    "currentAction",
    "camperId",
    "camperKey",
    "regKey",
  ],
};

const cartPersistConfig = {
  key: "cart",
  storage,
  blacklist: [
    "totalPrice",
    "totalDiscount",
    "isRegistrationAdded",
    "discountCode",
  ],
};

const uiPersistConfig = {
  key: "ui",
  storage,
  blacklist: ["activeRegisterStep", "mobileOpen", "alert", "promptDialog"],
};

const accountPersistConfig = {
  key: "account",
  storage,
  blacklist: [],
};

const errorPersistConfig = {
  key: "error",
  storage,
  blacklist: [],
};

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  register: persistReducer(registerPersistConfig, registerReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  ui: persistReducer(uiPersistConfig, uiReducer),
  account: persistReducer(accountPersistConfig, accountReducer),
  error: persistReducer(errorPersistConfig, errorReducer),
  [registerApi.reducerPath]: registerApi.reducer,
  [cartApi.reducerPath]: cartApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [errorApi.reducerPath]: errorApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // TODO restore this for production
  // devTools: process.env.NODE_ENV !== "production",
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      thunk,
      registerApi.middleware,
      cartApi.middleware,
      accountApi.middleware,
      authApi.middleware,
      errorApi.middleware
    ),
});

setupListeners(store.dispatch);

export default store;
