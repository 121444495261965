import PropTypes from "prop-types";
import { BrowserView, MobileView } from "react-device-detect";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { FormControl, FormHelperText, TextField } from "@mui/material";

const DateInput = ({ label, onChange, value, error, disabled, name }) => {
  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserView>
          <DesktopDatePicker
            label={label}
            name={name}
            inputFormat="MM/dd/yyyy"
            disableFuture
            disabled={disabled}
            value={value}
            onChange={(date) => {
              const dateStr = String(date);
              // omit if date is invalid
              if (dateStr !== "null" && dateStr !== "Invalid Date") {
                // transform date for cross compatibility
                // const dateValue = date.toISOString().slice(0, -1);
                onChange({
                  target: {
                    name,
                    value: date,
                  },
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={!!error}
                disabled={disabled}
                fullWidth
              />
            )}
          />
        </BrowserView>
        <MobileView>
          <MobileDatePicker
            label={label}
            name={name}
            inputFormat="MM/dd/yyyy"
            disableFuture
            disabled={disabled}
            value={value}
            onChange={(date) =>
              onChange({
                target: {
                  name,
                  value: date,
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={!!error}
                disabled={disabled}
                fullWidth
              />
            )}
          />
        </MobileView>
      </LocalizationProvider>
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

DateInput.defaultProps = {
  value: "",
};

export default DateInput;
