import { List, ListItem } from "@mui/material";

const ChosenFilesList = ({ files }) => {
  return files.length > 0 ? (
    <List>
      {files.map((file) => (
        <ListItem key={file.name}>{file.name}</ListItem>
      ))}
    </List>
  ) : null;
};

export default ChosenFilesList;
