import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const errorApi = createApi({
  reducerPath: "errorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitBugReport: builder.mutation({
      query: (data) => ({
        url: "submit_bug_report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useSubmitBugReportMutation } = errorApi;
