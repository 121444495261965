import { IconButton, TableCell, TableRow, useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import { PAY_DEPOSIT, PAY_FULL } from "../../../store/cart/constants";
import { useState } from "react";

const SessionLineItem = ({ session, handleRemoveItem, updatePayOption }) => {
  const {
    camperId,
    name,
    num,
    dates,
    paymentDueDate,
    totalDue,
    deposit,
    depositPaid,
  } = session;
  const smallScreen = useMediaQuery("(max-width:750px)");

  const [totalPayment, setTotalPayment] = useState(
    session.payOption === PAY_FULL ? totalDue : deposit
  );
  const [fullPaySelected, setFullPaySelected] = useState(
    session.payOption === PAY_FULL
  );

  const CampTableCell = withStyles({
    root: {
      borderBottom: "none",
      width: smallScreen ? "60%" : "40%",
    },
  })(TableCell);

  const StyledTableCell = withStyles({
    root: {
      borderBottom: "none",
      width: smallScreen ? "20%" : "30%",
    },
  })(TableCell);

  const StyledTableRow = withStyles({
    root: {
      border: "1px solid gray",
    },
  })(TableRow);

  function changeSessionPayment(e) {
    console.log(e);
    if (e.target.value === PAY_FULL) {
      setFullPaySelected(true);
      setTotalPayment(totalDue);
      updatePayOption(session.id, camperId, PAY_FULL);
    } else {
      setFullPaySelected(false);
      setTotalPayment(deposit);
      updatePayOption(session.id, camperId, PAY_DEPOSIT);
    }
  }

  if (smallScreen) {
    return (
      <StyledTableRow>
        <StyledTableCell>
          <Stack direction="row">
            <Stack direction="column" sx={{ width: "90%" }}>
              <Stack direction="column">
                <div>
                  {name + ": " + num} ({dates})
                </div>
              </Stack>
              <Typography variant="caption">
                Payment Due Date: {paymentDueDate}
              </Typography>
              <Typography variant="caption">
                Total Due: ${totalPayment}
              </Typography>
              {!depositPaid && (
                <RadioGroup
                  row
                  name={`payment-option-${session.id}`}
                  onChange={changeSessionPayment}
                >
                  <FormControlLabel
                    value={PAY_FULL}
                    control={
                      <Radio
                        size="small"
                        sx={{ p: "6px", paddingLeft: "0px" }}
                        checked={fullPaySelected}
                      />
                    }
                    label={
                      <Typography variant="caption">Pay in Full</Typography>
                    }
                    sx={{ m: 0 }}
                  />
                  <FormControlLabel
                    value={PAY_DEPOSIT}
                    control={
                      <Radio
                        size="small"
                        sx={{ p: "6px" }}
                        checked={!fullPaySelected}
                      />
                    }
                    label={
                      <Typography variant="caption">Pay deposit</Typography>
                    }
                    sx={{ m: 0 }}
                  />
                </RadioGroup>
              )}
            </Stack>
            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={() => handleRemoveItem(session.camperId, session.id)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Stack>
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <StyledTableRow>
      <CampTableCell>
        <Stack direction="column">
          <div>{name + ": " + num}</div>
          <div>{dates}</div>
        </Stack>
      </CampTableCell>
      <StyledTableCell>
        <Stack direction="column">
          <Typography variant="caption">Payment Due Date</Typography>
          <Typography variant="caption">{paymentDueDate}</Typography>
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Stack direction="column">
          <Typography variant="caption">Total Due</Typography>
          <Typography variant="caption">${totalPayment}</Typography>
          {!depositPaid && (
            <RadioGroup
              row
              name={`payment-option-${session.id}`}
              onChange={changeSessionPayment}
            >
              <FormControlLabel
                value={PAY_FULL}
                control={
                  <Radio
                    size="small"
                    sx={{ p: "6px" }}
                    checked={fullPaySelected}
                  />
                }
                label={<Typography variant="caption">Pay in Full</Typography>}
                sx={{ m: 0 }}
              />
              <FormControlLabel
                value={PAY_DEPOSIT}
                control={
                  <Radio
                    size="small"
                    sx={{ p: "6px" }}
                    checked={!fullPaySelected}
                  />
                }
                label={<Typography variant="caption">Pay deposit</Typography>}
                sx={{ m: 0 }}
              />
            </RadioGroup>
          )}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">
        <IconButton
          onClick={() => handleRemoveItem(session.camperId, session.id)}
        >
          <CloseIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default SessionLineItem;
