import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadForms } from "../../../store/account/thunks";
import { GeneralButton } from "../../../styles";
import useCamperInfo from "../../../hooks/useCamperInfo";
import { Stack } from "@mui/material";
import CamperDocumentBox from "./CamperDocumentBox";
import DocumentsHeader from "./DocumentsHeader";
import { accountActions } from "../../../store/account/slice";

const DocumentsTab = () => {
  const dispatch = useDispatch();

  const {
    camperInfo: campers,
    camperError,
    isFetchingCamperInfo,
    isSuccess,
    refetch,
  } = useCamperInfo();

  //shouldRefetch is set to true upon document upload, then camper info is refetched
  const shouldRefetch = useSelector((state) => state.account.shouldRefetch);

  const [formFiles, setFormFiles] = useState({});

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      dispatch(accountActions.clearShouldRefetch());
    }
  }, [shouldRefetch]);

  if (isFetchingCamperInfo) return <div>Getting camper info...</div>;

  if (camperError) return <div>Error getting camper info</div>;

  if (!campers) return null;

  function addFile(camperId, file) {
    setFormFiles((prevFiles) => {
      let newFiles = { ...prevFiles };
      if (camperId in prevFiles) {
        newFiles[camperId].push(file);
      } else {
        newFiles[camperId] = new Array(file);
      }
      return newFiles;
    });
  }

  function submitHandler(event) {
    event.preventDefault();

    let shouldSubmit = false;

    // create FormData object containing selected files
    const formData = new FormData();
    for (const camperId in formFiles) {
      formFiles[camperId].forEach((file) => {
        formData.append(`${camperId}[]`, file);
        if (!shouldSubmit) shouldSubmit = true;
      });
    }

    if (shouldSubmit) {
      //todo: use api instead of thunk, show notification on upload success
      dispatch(uploadForms({ formData }));
      setFormFiles([]);
    } else alert("No forms to submit");
  }

  return (
    <div style={{ marginBottom: "30px" }}>
      <DocumentsHeader />
      <form onSubmit={submitHandler} id="fileForm">
        <Stack direction="column" spacing={4}>
          {campers
            .filter((camper) => camper.isCurrent)
            .map((camper) => (
              <CamperDocumentBox
                key={camper.id}
                camper={camper}
                onFileAdd={addFile}
                files={formFiles[camper.id] || []}
              />
            ))}
        </Stack>
        <GeneralButton type="submit" variant="contained" fullWidth>
          Submit Forms
        </GeneralButton>
      </form>
    </div>
  );
};

export default DocumentsTab;
