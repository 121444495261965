import { Box, List, ListItem } from "@mui/material";
import { useState } from "react";
import ChooseFilesButton from "./ChooseFilesButton";

const FileDropZone = ({ name, camperId, files, addFile, minHeight }) => {
  const fileCount = files?.length || 0;

  const initialStyle = {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    border: "2px solid",
    width: "50%",
    background: "white",
  };

  // fileCount === 0 ? statusColors[status]

  const [style, setStyle] = useState(initialStyle);

  function dragOverHandler(event) {
    event.preventDefault();
    const newStyle = { ...initialStyle };
    newStyle.background = "lightblue";
    setStyle(newStyle);
  }

  function dragLeaveHandler() {
    setStyle(initialStyle);
  }

  function dropHandler(event) {
    event.preventDefault();
    if (event.dataTransfer.items) {
      Array.from(event.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file.type === "application/pdf") {
            if (!isDuplicateFile(file.name)) {
              addFile(camperId, file);
            } else alert("File already chosen");
          } else alert("Wrong file type");
        }
      });
    }
    dragLeaveHandler();
  }

  function fileChangeHandler(event) {
    Array.from(event.target.files).forEach((file) => {
      if (!isDuplicateFile(file.name)) {
        addFile(camperId, file);
      }
    });
  }

  function isDuplicateFile(fileName) {
    return (
      files?.some((existingFile) => existingFile.name === fileName) || false
    );
  }

  return (
    <Box
      style={style}
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={dropHandler}
      minHeight={minHeight || ""}
    >
      {fileCount === 0 && (
        <div>
          Drag and drop files here for {name}
          <br />
          OR
          <br />
        </div>
      )}
      {fileCount !== 0 && (
        <List dense>
          {files.map((file) => (
            <ListItem key={file.name}>{file.name}</ListItem>
          ))}
        </List>
      )}
      <Box sx={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}>
        <ChooseFilesButton
          camperId={camperId}
          addFile={addFile}
          files={files}
        />
      </Box>
      {/* fileCount === 0 && <div>Status: {status}</div> */}
    </Box>
  );
};

export default FileDropZone;
