import { useParams } from "react-router-dom";
import useReceipts from "../../../hooks/useReceipts";
import LoadingScreen from "../../UI/LoadingScreen";
import Receipt from "./Receipt";

const ReceiptPage = () => {
  const { receipts, receiptsError, isFetchingReceipts, isSuccess } =
    useReceipts();
  const { id } = useParams();

  if (isFetchingReceipts) return <LoadingScreen />;

  if (isSuccess) {
    const receipt = receipts.find((receipt) => +receipt.receiptId === +id);
    if (receipt) return <Receipt receipt={receipt} />;
    else return <div>Invalid receipt ID</div>;
  }

  return <div>Receipt error or no receipt found</div>;
};

export default ReceiptPage;
