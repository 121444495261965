import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProcessPaymentMutation } from "../../../store/account/api";
import { uiActions } from "../../../store/ui/slice";
import PaymentForm from "../../Checkout/PaymentForm";
import SessionLineItem from "./SessionLineItem";
import SessionList from "./SessionList";
import AddIcon from "@mui/icons-material/Add";
import { PAY_DEPOSIT } from "../../../store/cart/constants";
import { List } from "@mui/material";
import { ListItemText } from "@mui/material";
import { ListItem } from "@mui/material";
import { Divider } from "@mui/material";
import TabHeader from "../TabHeader";

const MakePayment = ({ campers, chosenSessions, otherSessions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:750px)");

  //sessions that are currently selected to be paid for
  const [chosenSessionsByCamper, setChosenSessionsByCamper] = useState({});
  //sessions that are not currently selected to be paid for (can be added to chosen sessions list)
  const [otherSessionsByCamper, setOtherSessionsByCamper] = useState({});

  const [showSessionList, setShowSessionList] = useState(false);
  const [isPaying, setIsPaying] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallScreen ? "90%" : "740px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflow: "auto",
  };

  const [
    processPayment,
    {
      data: processPaymentData,
      error: processPaymentError,
      isUninitialized: isUninitProcessPayment,
      isSuccess: isSuccessProcessPayment,
      isError: isErrorProcessPayment,
    },
  ] = useProcessPaymentMutation();

  useEffect(() => {
    if (isSuccessProcessPayment) {
      dispatch(uiActions.setAlertSuccess("Payment successful"));
      navigate("/account");
    } else if (isErrorProcessPayment) {
      console.error(processPaymentError);
      dispatch(
        uiActions.setAlertError("Failed to process payment. Please try again.")
      );
    }
  }, [isSuccessProcessPayment, isErrorProcessPayment]);

  const updatePayOption = useCallback((sessionId, camperId, payOption) => {
    setChosenSessionsByCamper((prevState) => {
      let newSessions = [...prevState[camperId]];
      let newSession = {
        ...newSessions.find((session) => +session.id === +sessionId),
      };
      newSession.payOption = payOption;
      return {
        ...prevState,
        [camperId]: newSessions.map((session) =>
          +session.id === +sessionId ? newSession : session
        ),
      };
    });
  });

  const handleSubmit = (payeezyData) => {
    const paymentData = {
      ...payeezyData,
      camperSessionsToPay: chosenSessionsByCamper,
    };
    processPayment(paymentData);
  };

  const colors = ["#97A7B7AA", "#B2C2B9AA", "#BDD6E0AA", "#C7DBA9AA"];
  //["#CBC4BC33", "#522D8033", "#005EB833", "#54622333"];

  useEffect(() => {
    //associate currently chosen sessions with camper IDs
    if (chosenSessions && campers) {
      let sessionsByCamper = {};
      campers.forEach((camper) => {
        const chosenSessionsForCamper = chosenSessions.filter(
          (session) => session.camperId === camper.id && session.totalDue > 0
        );
        if (chosenSessionsForCamper.length > 0)
          sessionsByCamper[camper.id] = chosenSessionsForCamper;
      });
      setChosenSessionsByCamper(sessionsByCamper);

      //associate sessions that are not currently chosen with camper IDs
      if (otherSessions && campers) {
        let otherSessionsByCamper = {};
        campers.forEach((camper) => {
          const otherSessionsForCamper = otherSessions.filter(
            (session) => session.camperId === camper.id && session.totalDue > 0
          );
          if (otherSessionsForCamper.length > 0)
            otherSessionsByCamper[camper.id] = otherSessionsForCamper;
        });
        setOtherSessionsByCamper(otherSessionsByCamper);
      }
    }
  }, [chosenSessions, campers, otherSessions]);

  if (!chosenSessions) return null;

  /**
   * Get the total amount due for the currently chosen sessions
   */
  function getTotal() {
    let total = 0;
    Object.keys(chosenSessionsByCamper).forEach((camperId) => {
      chosenSessionsByCamper[camperId].forEach((session) => {
        if (session.payOption === PAY_DEPOSIT) total += session.deposit;
        else total += session.totalDue;
      });
    });
    return total;
  }

  /**
   * Remove an item from the chosen sessions list and add it to the other sessions list
   */
  function handleRemoveItem(camperId, sessionId) {
    // if the camper ID is not already in the object, add it
    if (!otherSessionsByCamper[camperId]) {
      setOtherSessionsByCamper((prevState) => ({
        ...prevState,
        [camperId]: [
          chosenSessionsByCamper[camperId].find(
            (session) => +session.id === +sessionId
          ),
        ],
      }));
    }

    // if the camper is already in the object, add the not-chosen session to their array
    else {
      setOtherSessionsByCamper((prevState) => {
        let newOtherSessions = [
          ...prevState[camperId],
          chosenSessionsByCamper[camperId].find(
            (session) => +session.id === +sessionId
          ),
        ];
        return {
          ...prevState,
          [camperId]: newOtherSessions,
        };
      });
    }

    // remove the session from the camper's chosen sessions array
    let newSessions = chosenSessionsByCamper[camperId].filter(
      (session) => session.id !== sessionId
    );
    if (newSessions.length === 0) {
      setChosenSessionsByCamper((prevState) => {
        let newCampers = { ...prevState };
        delete newCampers[camperId];
        return newCampers;
      });
    } else
      setChosenSessionsByCamper((prevState) => {
        return {
          ...prevState,
          [camperId]: newSessions,
        };
      });
  }

  /**
   * Add an item to the chosen sessions list and remove it from the other sessions list
   */
  function handleAddItem(camperId, sessionId) {
    // if the camper ID is not already in the object, add it
    if (!chosenSessionsByCamper[camperId]) {
      setChosenSessionsByCamper((prevState) => ({
        ...prevState,
        [camperId]: [
          otherSessionsByCamper[camperId].find(
            (session) => +session.id === +sessionId
          ),
        ],
      }));
    }

    // if the camper is already in the object, add the new session to their session array
    else {
      setChosenSessionsByCamper((prevState) => {
        let newSessions = [
          ...prevState[camperId],
          otherSessionsByCamper[camperId].find(
            (session) => +session.id === +sessionId
          ),
        ];
        return {
          ...prevState,
          [camperId]: newSessions,
        };
      });
    }

    // remove the session from the list of available sessions to add
    let newOtherSessions = otherSessionsByCamper[camperId].filter(
      (session) => +session.id !== +sessionId
    );
    if (newOtherSessions.length === 0) {
      let newCampers = { ...otherSessionsByCamper };
      delete newCampers[camperId];
      if (Object.keys(newCampers).length === 0) setShowSessionList(false);
      setOtherSessionsByCamper(newCampers);
    } else
      setOtherSessionsByCamper({
        ...otherSessionsByCamper,
        [camperId]: newOtherSessions,
      });
  }

  function toggleSessionList() {
    setShowSessionList((prevState) => !prevState);
  }

  if (isPaying && getTotal() <= 0) {
    setIsPaying(false);
  }

  return !isPaying ? (
    <Fragment>
      <TabHeader text={"Make a Payment"} />
      {chosenSessions &&
        Object.keys(chosenSessionsByCamper).map((camperId, index) => {
          return (
            <Card
              key={camperId}
              sx={{
                marginBottom: "10px",
                padding: "10px",
                bgcolor: colors[index % colors.length],
              }}
            >
              <Box sx={{ marginLeft: "5px", marginBottom: "5px" }}>
                <Typography variant="h5">
                  {campers.find((camper) => +camper.id === +camperId).fullName}
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableBody>
                    {chosenSessionsByCamper[camperId].map((session) => (
                      <SessionLineItem
                        key={session.id}
                        session={session}
                        handleRemoveItem={handleRemoveItem}
                        updatePayOption={updatePayOption}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          );
        })}
      {Object.keys(otherSessionsByCamper).length > 0 && (
        <Card
          sx={{
            marginBottom: "10px",
            padding: 0,
            height: "100px",
            bgcolor: "#F2F2F2",
          }}
        >
          <Button
            onClick={toggleSessionList}
            sx={{ width: "100%", height: "100%" }}
          >
            <span>
              <AddIcon />
              <div>Add Sessions</div>
            </span>
          </Button>
        </Card>
      )}
      <Stack direction="column" spacing={2}>
        <Typography component="div">
          {getTotal() > 0 && (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                marginTop: "15px",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Total Payment: ${getTotal()}
            </Box>
          )}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setIsPaying(true)}
          disabled={getTotal() <= 0}
        >
          Continue to Payment
        </Button>
      </Stack>
      <SessionList
        campers={campers}
        sessions={otherSessionsByCamper}
        handleAddSession={handleAddItem}
        open={showSessionList}
        style={style}
        onClose={toggleSessionList}
      />
    </Fragment>
  ) : (
    <Fragment>
      <Typography variant="h4">Checkout</Typography>
      <List>
        {Object.keys(chosenSessionsByCamper).map((camperId) => {
          return chosenSessionsByCamper[camperId].map((session) => {
            return (
              <Fragment key={session.id}>
                <ListItem key={session.id}>
                  <ListItemText
                    primary={session.name + " " + session.num + ""}
                    secondary={session.dates}
                  />
                  <Typography variant="subtitle1">
                    $
                    {session.payOption === PAY_DEPOSIT
                      ? session.deposit
                      : session.totalDue}
                  </Typography>
                </ListItem>
                <Divider />
              </Fragment>
            );
          });
        })}
      </List>
      <Typography variant="h5" sx={{ marginTop: "25px", marginBottom: "5px" }}>
        Payment Details
      </Typography>
      <PaymentForm
        isRegistering={false}
        onSubmit={handleSubmit}
        totalPrice={getTotal()}
        onSubmitMsg="Processing payment..."
      />
      <Button onClick={() => setIsPaying(false)} sx={{ marginTop: "15px" }}>
        Back
      </Button>
    </Fragment>
  );
};

export default MakePayment;
