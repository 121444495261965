import { useTheme } from "@emotion/react";
import { Card, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAY_DEPOSIT, PAY_FULL } from "../../store/cart/constants";
import { cartActions } from "../../store/cart/slice";

const OrderSummary = () => {
  const { registrations } = useSelector((state) => state.cart);
  const { programType } = useSelector((state) => state.account);
  const theme = useTheme();
  const [paidInFullTotal, setPaidInFullTotal] = useState(0);
  const [depositTotal, setDepositTotal] = useState(0);
  const [remainingTotal, setRemainingTotal] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let fullTotal = getPaidInFullTotal();
    let depositTotal = getDepositTotal();
    getRemainingTotal();

    dispatch(cartActions.setTotalPrice(fullTotal + depositTotal));
  }, []);

  function getPaidInFullTotal() {
    let total = registrations.reduce((partialSum, current) => {
      if (
        current.programType.includes(programType) &&
        current.payOption === PAY_FULL
      )
        //add cost of session minus sum of discounts
        return (
          partialSum +
          current.totalCost -
          current.discounts.reduce((sum, cur) => sum + cur.amount, 0)
        );
      else return partialSum;
    }, 0);
    setPaidInFullTotal(total);
    return total;
  }

  function getDepositTotal() {
    let total = registrations.reduce((partialSum, current) => {
      if (
        current.programType.includes(programType) &&
        current.payOption === PAY_DEPOSIT
      )
        return partialSum + current.depositAmount;
      else return partialSum;
    }, 0);
    setDepositTotal(total);
    return total;
  }

  function getRemainingTotal() {
    let total = 0;
    registrations.forEach((reg) => {
      if (
        reg.programType.includes(programType) &&
        reg.payOption === PAY_DEPOSIT
      ) {
        total += reg.totalCost - reg.depositAmount;
        total -= reg.discounts.reduce((sum, cur) => sum + cur.amount, 0);
      }
    });
    setRemainingTotal(total);
    return total;
  }

  return (
    <Card sx={{ borderRadius: 0, padding: "1rem" }}>
      <Stack direction="column">
        <div>
          <Typography variant="body" color={theme.palette.headers.main}>
            Programs paid in full
          </Typography>
          <Typography
            variant="body"
            color={theme.palette.headers.main}
            sx={{ float: "right" }}
          >
            ${paidInFullTotal}
          </Typography>
        </div>
        <div>
          <Typography variant="body" color={theme.palette.headers.main}>
            Nonrefundable deposits
          </Typography>
          <Typography
            variant="body"
            color={theme.palette.headers.main}
            sx={{ float: "right" }}
          >
            ${depositTotal}
          </Typography>
        </div>
        <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
        <div>
          <Typography
            variant="body"
            color={theme.palette.headers.main}
            sx={{ fontWeight: "bold" }}
          >
            Today's total
          </Typography>
          <Typography
            variant="body"
            color="#AC4E1E"
            sx={{ float: "right", fontWeight: "bold" }}
          >
            ${paidInFullTotal + depositTotal}
          </Typography>
        </div>
        {remainingTotal > 0 && (
          <Typography
            variant="caption"
            color="#888B8D"
            sx={{ marginTop: "6px" }}
          >
            ${remainingTotal} remaining balance due 4 weeks before camp.
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

export default OrderSummary;
