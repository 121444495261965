import {
  useForgotPasswordMutation,
  useRefreshTokenMutation,
  useSignInMutation,
  useSignUpMutation,
  useUpdatePasswordMutation,
  useValidateTokenMutation,
} from "./api";

export const useSignIn = () => {
  const [submitSignIn, response] = useSignInMutation();

  const {
    data: signInData,
    error: signInError,
    isLoading: isLoadingSignIn,
    isSuccess: isSuccessSignIn,
    isError: isErrorSignIn,
    reset: resetSignIn,
  } = response;

  return {
    submitSignIn,
    signInData,
    signInError,
    isLoadingSignIn,
    isSuccessSignIn,
    isErrorSignIn,
    resetSignIn,
  };
};

export const useSignUp = () => {
  const [submitSignUp, response] = useSignUpMutation();

  const {
    data: signUpData,
    error: signUpError,
    isLoading: isLoadingSignUp,
    isSuccess: isSuccessSignUp,
    isError: isErrorSignUp,
    reset: resetSignUp,
  } = response;

  return {
    submitSignUp,
    signUpData,
    signUpError,
    isLoadingSignUp,
    isSuccessSignUp,
    isErrorSignUp,
    resetSignUp,
  };
};

export const useForgotPassword = () => {
  const [submitForgotPassword, response] = useForgotPasswordMutation();

  const {
    data: forgotPasswordData,
    error: forgotPasswordError,
    isLoading: isLoadingForgotPassword,
    isSuccess: isSuccessForgotPassword,
    isError: isErrorForgotPassword,
    reset: resetForgotPassword,
  } = response;

  return {
    submitForgotPassword,
    forgotPasswordData,
    forgotPasswordError,
    isLoadingForgotPassword,
    isSuccessForgotPassword,
    isErrorForgotPassword,
    resetForgotPassword,
  };
};

export const useUpdatePassword = () => {
  const [submitUpdatePassword, response] = useUpdatePasswordMutation();

  const {
    data: updatePasswordData,
    error: updatePasswordError,
    isLoading: isLoadingUpdatePassword,
    isSuccess: isSuccessUpdatePassword,
    isError: isErrorUpdatePassword,
    reset: resetUpdatePassword,
  } = response;

  return {
    submitUpdatePassword,
    updatePasswordData,
    updatePasswordError,
    isLoadingUpdatePassword,
    isSuccessUpdatePassword,
    isErrorUpdatePassword,
    resetUpdatePassword,
  };
};

export const useValidateToken = () => {
  const [submitValidateToken, response] = useValidateTokenMutation();

  const {
    data: validateTokenData,
    error: validateTokenError,
    isUninitialized: isUninitializedValidateToken,
    isLoading: isLoadingValidateToken,
    isSuccess: isSuccessValidateToken,
    isError: isErrorValidateToken,
    reset: resetValidateToken,
  } = response;

  return {
    submitValidateToken,
    validateTokenData,
    validateTokenError,
    isUninitializedValidateToken,
    isLoadingValidateToken,
    isSuccessValidateToken,
    isErrorValidateToken,
    resetValidateToken,
  };
};

export const useRefreshToken = () => {
  const [submitRefreshToken, response] = useRefreshTokenMutation();

  const {
    data: refreshTokenData,
    error: refreshTokenError,
    isUninitialized: isUninitializedRefreshToken,
    isLoading: isLoadingRefreshToken,
    isSuccess: isSuccessRefreshToken,
    isError: isErrorRefreshToken,
    reset: resetRefreshToken,
  } = response;

  return {
    submitRefreshToken,
    refreshTokenData,
    refreshTokenError,
    isUninitializedRefreshToken,
    isLoadingRefreshToken,
    isSuccessRefreshToken,
    isErrorRefreshToken,
    resetRefreshToken,
  };
};
