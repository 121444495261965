import { CircularProgress, styled } from "@mui/material";

const CompatibleLoader = styled(CircularProgress)(() => ({
  ".MuiCircularProgress-indeterminate": {
    animation: "circular-rotate 1.4s linear infinite",
  },
  "@keyframes circular-rotate": {
    "0%": {
      transform: "rotate(0deg)",
      /* Fix IE11 wobbly */
      transformOrigin: "50% 50%",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const Loader = () => {
  return <CompatibleLoader disableShrink />;
};

export default Loader;
