import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import AccountLayout from "./UI/AccountLayout";
import LoadingScreen from "./UI/LoadingScreen";
import { authActions } from "../store/auth/slice";
import CartLayout from "./UI/CartLayout";
import {
  ACCOUNT_LAYOUT,
  CART_LAYOUT,
  DEFAULT_LAYOUT,
  MSG_AUTH_REQUIRED,
  MSG_ERR_REFRESH_SESSION,
  MSG_SESSION_TIMED_OUT,
} from "../store/ui/constants";
import DefaultLayout from "./UI/DefaultLayout";
import { useRefreshToken } from "../store/auth/hooks";
import { uiActions } from "../store/ui/slice";
import TokenTimer from "./Authentication/TokenTimer";
import { useLocation } from "react-router-dom";
import { accountActions } from "../store/account/slice";
import { TYPE_CCATS, TYPE_SUMMER_CAMP } from "../store/account/constants";

const ProtectedRoute = ({ redirectPath = "", layout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signinLink = `${redirectPath}/signin`;
  const [tokenReady, setTokenReady] = useState(false);
  const location = useLocation();

  const {
    submitRefreshToken,
    refreshTokenData,
    isErrorRefreshToken,
    isSuccessRefreshToken,
    refreshTokenError,
    isUninitializedRefreshToken,
    isLoadingRefreshToken,
  } = useRefreshToken();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      submitRefreshToken();
    } else {
      console.log("No token: " + token);
      dispatch(uiActions.setAlertInfo(MSG_AUTH_REQUIRED));
      navigate(signinLink);
    }

    const path = location.pathname;
    if (path.includes("/register") || path === "/") {
      if (path.includes("/ccats")) {
        dispatch(accountActions.setProgramType(TYPE_CCATS));
      } else {
        dispatch(accountActions.setProgramType(TYPE_SUMMER_CAMP));
      }
    }
  }, []);

  useEffect(() => {
    if (isUninitializedRefreshToken || isLoadingRefreshToken) {
      return <LoadingScreen />;
    }
  }, [isUninitializedRefreshToken, isLoadingRefreshToken]);

  useEffect(() => {
    if (isSuccessRefreshToken) {
      localStorage.setItem("token", refreshTokenData);
      dispatch(authActions.refreshTokenData(refreshTokenData));
      setTokenReady(true);
      //dispatch(authActions.resetCustomerId());
    }
  }, [isSuccessRefreshToken]);

  useEffect(() => {
    if (isErrorRefreshToken) {
      console.error(refreshTokenError);
      if (refreshTokenError.data.includes("expired")) {
        dispatch(uiActions.setAlertInfo(MSG_SESSION_TIMED_OUT));
      } else {
        dispatch(uiActions.setAlertError(MSG_ERR_REFRESH_SESSION));
      }
      navigate(signinLink);
    }
  }, [isErrorRefreshToken, refreshTokenError]);

  const renderContent = () => {
    if (isSuccessRefreshToken) {
      switch (layout) {
        case DEFAULT_LAYOUT:
          return (
            <DefaultLayout>
              <Outlet />
            </DefaultLayout>
          );
        case CART_LAYOUT:
          return (
            <CartLayout>
              <Outlet />
            </CartLayout>
          );
        case ACCOUNT_LAYOUT:
          return (
            <AccountLayout>
              <Outlet />
            </AccountLayout>
          );
        default:
          return <Outlet />;
      }
    } else {
      return <LoadingScreen />;
    }
  };

  return (
    <>
      {tokenReady && renderContent()}
      {isSuccessRefreshToken && (
        <>
          <TokenTimer />
        </>
      )}
    </>
  );
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  layout: PropTypes.number,
};

export default ProtectedRoute;
