import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cartActions } from "../../store/cart/slice";

import { Stack } from "@mui/material";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CamperItem from "../Registration/CamperItem";
import PageHeading from "../UI/PageHeading";
import SecondaryHeading from "../UI/SecondaryHeading";
import OrderSummary from "./OrderSummary";

// interface allowing a customer to change how they want to pay for registrations in the cart (deposit/full)
const PaymentSummary = () => {
  const dispatch = useDispatch();
  const { campers, registrations, totalPrice, totalDiscount } = useSelector(
    (state) => state.cart
  );
  const programType = useSelector((state) => state.account.programType);
  const ptCampers = campers.filter((camper) => {
    let hasProgramType = false;
    registrations.forEach((reg) => {
      if (reg.camperKey === camper.key) {
        if (reg.programType.includes(programType)) {
          hasProgramType = true;
        }
      }
    });
    return hasProgramType;
  });

  // initialize form pay options using values in redux
  useEffect(() => {
    dispatch(cartActions.updateRemainingBalances());
    dispatch(cartActions.updatePayAmounts(programType));
  }, []);

  // NOTE this doesn't work when discount values vary by registration
  const showDiscountTotalsForCamper = (camper) => {
    const camperRegistrations = registrations.filter(
      (reg) =>
        reg.camperKey === camper.key && reg.programType.includes(programType)
    );

    // calculate total discounts applied to this camper
    const returnDiscountTotal = camperRegistrations.reduce((a, b) => {
      if (b?.discounts?.return) return a + 1;
    }, 0);
    const ebdDiscountTotal = camperRegistrations.reduce((a, b) => {
      if (b?.discounts?.ebd) return a + 1;
    }, 0);
    const returnAmount = camperRegistrations[0].returnDiscount;
    const ebdAmount = camperRegistrations[0].ebdAmount;

    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" align="left">
          Discounts:
        </Typography>
        <Stack direction="column">
          {returnDiscountTotal > 0 && (
            <Typography
              variant="body2"
              align="right"
              color="green"
              noWrap
            >{`-$${
              returnDiscountTotal * returnAmount
            } Returning Camper (x${returnDiscountTotal})`}</Typography>
          )}
          {ebdDiscountTotal > 0 && (
            <Typography
              variant="body2"
              align="right"
              color="green"
              noWrap
            >{`-$${
              ebdDiscountTotal * ebdAmount
            } Early Bird (x${ebdDiscountTotal})`}</Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <Fragment>
      <Stack spacing={2}>
        <PageHeading text={"Confirm & Pay"} />
        <SecondaryHeading text={"Camper Information"} />
        {ptCampers.map((camper) => {
          return (
            <CamperItem
              key={camper.id}
              camper={camper}
              showOptions={false}
              showCamperInfo={true}
            />
          );

          // return (
          //   <Card key={camper.key}>
          //     <Divider />
          //     <CardContent
          //       sx={{ padding: 0, "&:last-child": { paddingBottom: "8px" } }}
          //     >
          //       <Box>
          //         <Grid
          //           container
          //           justifyContent="space-between"
          //           sx={{ padding: "8px" }}
          //         >
          //           <Grid item xs={12}>
          //             {showDiscountTotalsForCamper(camper)}
          //           </Grid>
          //         </Grid>
          //       </Box>
          //       <Divider />
          //       <Box p={1}>{showCamperTotals(camper)}</Box>
          //     </CardContent>
          //   </Card>
          // );
        })}
      </Stack>
      <SecondaryHeading text={"Order Summary"} />
      <OrderSummary />
    </Fragment>
  );
};

export default PaymentSummary;
