import { memo, useCallback, useEffect, useState } from "react";

//the react-countdown library timer wasn't always working, so I just made my own
const CountdownTimer = ({ renderer, onComplete, date }) => {
  const [timer, setTimer] = useState();

  let hours = (timer / 3600) | 0;
  let minutes = ((timer % 3600) / 60) | 0;
  let seconds = Math.round(timer % 60) | 0;
  if (seconds === 60) {
    seconds = 0;
    minutes++;
  }

  function zeroPad(num) {
    return String(num).padStart(2, "0");
  }

  const isComplete = useCallback(() => {
    return hours <= 0 && minutes <= 0 && seconds <= 0;
  }, [hours, minutes, seconds]);

  useEffect(() => {
    if (timer && !isComplete()) {
      const timerInterval = setInterval(function () {
        setTimer(Math.floor((date - Date.now()) / 1000));
      }, 1000);

      return () => {
        clearInterval(timerInterval);
      };
    } else if (timer === undefined) {
      //set timer initial state
      const time = Math.floor((date - Date.now()) / 1000);
      setTimer(time);
    } else if (timer !== undefined && isComplete()) {
      onComplete();
    }
  }, [timer, date, isComplete, onComplete]);

  return renderer({
    hours,
    minutes,
    seconds,
    isComplete: isComplete(),
    zeroPad,
  });
};

export default memo(CountdownTimer);
