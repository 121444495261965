import { Link } from "react-router-dom";
import TextInput from "../UI/TextInput";
import { Button, Typography } from "@mui/material";

const SignUpForm = ({ form, errors, onChange, redirectPath = "/" }) => {
  const signinLink = `${redirectPath}signin`;

  return (
    <>
      <Typography variant="h5" align="left">
        Create a Parent Account
      </Typography>
      <Typography variant="caption">
        To register children for summer camp, please create an account.
      </Typography>
      <TextInput
        type="text"
        name="email"
        label="Email"
        value={form.email}
        onChange={onChange}
        error={errors.email}
        autoComplete="email"
      />
      <TextInput
        type="text"
        name="confirmEmail"
        label="Confirm Email"
        value={form.confirmEmail}
        onChange={onChange}
        error={errors.confirmEmail}
        autoComplete="email"
      />
      <TextInput
        type="password"
        name="password"
        label="Password"
        value={form.password}
        onChange={onChange}
        error={errors.password}
        autoComplete="new-password"
      />
      <TextInput
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        value={form.confirmPassword}
        onChange={onChange}
        error={errors.confirmPassword}
        autoComplete="new-password"
      />
      <Typography variant="body2">
        Already have an account? <Link to={signinLink}>Sign in</Link>
      </Typography>
      <Button type="submit" variant="contained" fullWidth>
        Sign up
      </Button>
    </>
  );
};

export default SignUpForm;
