import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { uiActions } from "../../store/ui/slice";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = ({ open, message, severity }) => {
  const dispatch = useDispatch();

  // const handleClose = (event, reason) => {
  // do not close on clickaway
  // if (reason === "clickaway") {
  //   return;
  // }
  const handleClose = () => {
    dispatch(uiActions.clearAlert());
  };

  const renderAlert = () => {
    if (open) {
      return (
        <Alert severity={severity} onClose={handleClose} sx={{ width: "100%" }}>
          {message}
        </Alert>
      );
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={30000} onClose={handleClose}>
        {renderAlert()}
      </Snackbar>
    </Stack>
  );
};

AlertMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
};

export default AlertMessage;
