import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ height: "100vh" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
      >
        <Grid item>
          <Paper elevation={8}>
            <Box p={5}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: `100%`,
                }}
              >
                <Typography variant="h2">404</Typography>
                <Typography variant="h4">Page not found</Typography>
                <Button
                  variant="contained"
                  aria-label="home"
                  onClick={() => navigate(-1)}
                  sx={{ mt: "2rem" }}
                >
                  Go Back
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
