import { Fragment, memo } from "react";
import PropTypes from "prop-types";

import RegistrationItemMenu from "./RegistrationItemMenu";
import { CardHeader, Grid, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { PAY_FULL } from "../../store/cart/constants";

const logoProperties = { width: "42px", height: "42px" };

// lists the registrations for a camper within the cart
const RegistrationItem = ({ camper, registration, showOptions }) => {
  const imgSrc = registration.logoSrc
    ? `${process.env.REACT_APP_DOMAIN}/${registration.logoSrc}`
    : `${process.env.REACT_APP_DOMAIN}/images/camps/logos/YLI-CAMPS-color.png`;

  const theme = useTheme();

  function getDiscountsList() {
    let list = "";
    registration.discounts.forEach(
      (discount) =>
        (list +=
          " \u2022 " +
          discount.name.charAt(0).toUpperCase() +
          discount.name.slice(1))
    );
    return list;
  }

  return (
    <>
      <CardHeader
        avatar={
          <img alt="camp logo" src={imgSrc} style={{ ...logoProperties }} />
        }
        action={
          showOptions ? (
            <RegistrationItemMenu camper={camper} registration={registration} />
          ) : null
        }
        title={
          <Grid container>
            <Grid item xs={8}>
              <Typography fontWeight="bold" color={theme.palette.headers.main}>
                {registration.name}
              </Typography>
            </Grid>
            {!showOptions && (
              <Grid item xs={4}>
                <Typography
                  sx={{ float: "right" }}
                  color={theme.palette.headers.main}
                >
                  ${registration.payAmount}{" "}
                </Typography>
              </Grid>
            )}
          </Grid>
        }
        subheader={`${registration.dates}${
          registration.discounts.length > 0 ? getDiscountsList() : ""
        }`}
        sx={{
          padding: "0",
          cursor: "default",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "8px",
        }}
      />
    </>
  );
};

RegistrationItem.propTypes = {
  camper: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
};

export default memo(RegistrationItem);
