import { Fragment } from "react";

const DocumentsHeader = () => {
  const style = {
    marginTop: "15px",
    background: "#e5e5e5",
    border: "2px solid #ddd",
    position: "relative",
    width: "100%",
    padding: "10px",
    borderRadius: "10px",
    marginBottom: "15px",
    fontSize: "16px",
    lineHeight: "1.5",
  };

  return (
    <Fragment>
      <h1>Forms</h1>

      <p>
        Please download each camper&apos;s form, fill it out, and upload it
        here.
      </p>

      <div style={style}>
        <h3>How to Upload the Form</h3>
        <ol>
          <li>
            Download, print and complete the{" "}
            <strong>required camp forms</strong> below.
          </li>
          <li>Once complete, scan the documents into your computer.</li>
          <li>
            Upload the documents below. Note: We can only accept documents in
            PDF format. Please upload all of your documents at once.
          </li>
        </ol>
        <p>
          If you have questions, call 864-878-1103 or email{" "}
          <a href="mailto:info@ylicamps.com">info@ylicamps.com</a>.
        </p>
        <p>
          <small>Our business hours are Monday-Friday, 8:00am-4:30pm</small>
        </p>
      </div>
    </Fragment>
  );
};

export default DocumentsHeader;
