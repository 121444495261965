import { useSelector } from "react-redux";
import { AgeFromDateString } from "age-calculator";
import PropTypes from "prop-types";

import RegistrationItem from "./RegistrationItem";
import CamperItemMenu from "./CamperItemMenu";
import { theme } from "../../styles";

import { Card } from "@mui/material";
import { CardHeader } from "@mui/material";
import { CardContent } from "@mui/material";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import { Divider } from "@mui/material";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { TYPE_CCATS } from "../../store/account/constants";
import { useTheme } from "@emotion/react";

// displays camper info and their registrations in the cart
const CamperItem = ({
  camper,
  onAddCamp = () => {},
  showOptions = true,
  editCamper = () => {},
  showCamperInfo = false,
}) => {
  const registrations = useSelector((state) =>
    state.cart.registrations.filter((reg) => reg.camperKey === camper.key)
  );
  const programType = useSelector((state) => state.account.programType);

  const fullName = `${camper.firstName} ${camper.lastName}`;
  const bioDetails = `${camper.sex}${
    programType === TYPE_CCATS
      ? ""
      : " \u2022 " + new Date(camper.birthdate).toLocaleDateString()
  }`;
  const theme = useTheme();

  return (
    <Card sx={{ width: "100%", borderRadius: 0 }}>
      <CardHeader
        action={
          showOptions ? (
            <IconButton onClick={() => editCamper(camper)}>
              <EditIcon />
            </IconButton>
          ) : null
        }
        title={`${fullName}${showCamperInfo ? ` \u2022 ${bioDetails}` : ""}`}
        sx={{
          paddingLeft: "1.3rem",
          marginBottom: 0,
          cursor: "default",
          color: theme.palette.headers.main,
        }}
      />
      <Divider sx={{ marginBottom: "0.6rem" }} />
      <CardContent
        sx={{
          padding: "0 1rem 1rem 1rem",
          "&:last-child": { paddingBottom: 0 },
        }}
      >
        {registrations
          .filter((reg) => reg.programType.includes(programType))
          .map((reg) => (
            <>
              <RegistrationItem
                key={reg.key}
                camper={camper}
                registration={reg}
                showOptions={showOptions}
              />
              <Divider sx={{ marginBottom: "0.5rem" }} />
            </>
          ))}
        {!programType.includes(TYPE_CCATS) && showOptions && (
          <Stack
            direction="row"
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
              paddingLeft: 0,
              cursor: "pointer",
            }}
            onClick={() => onAddCamp(camper)}
          >
            <AddIcon
              sx={{
                padding: 0,
                marginRight: "4px",
                width: "20px",
                height: "20px",
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              Add another week of camp for {camper.firstName}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

CamperItem.propTypes = {
  camper: PropTypes.object.isRequired,
};

export default CamperItem;
