import { useGetCamperInfoQuery } from "../store/account/api";
import { useDispatch, useSelector } from "react-redux";

const useCamperInfo = () => {
  const customerId = useSelector((state) => state.auth.customerId);
  const programType = useSelector((state) => state.account.programType);
  //const programType = 25; //C-Cats Middle School (for testing)
  //const programType = undefined;
  const skip = !customerId;
  const dispatch = useDispatch();

  const {
    data: camperInfo,
    error: camperError,
    isFetching: isFetchingCamperInfo,
    isSuccess: isSuccessCamperInfo,
    isError: isErrorCamperInfo,
    refetch,
  } = useGetCamperInfoQuery({ customerId, programType }, { skip });
  //programType should be "Summer Camp", "C-CATS", or ""

  return {
    camperInfo,
    camperError,
    isFetchingCamperInfo,
    isErrorCamperInfo,
    isSuccessCamperInfo,
    refetch,
  };
};

export default useCamperInfo;
