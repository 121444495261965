import PropTypes from "prop-types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

const SelectInput = ({
  name,
  label,
  value,
  onChange,
  required,
  options,
  hasError,
  disabled,
  error,
  variant,
  size,
}) => {
  return (
    <FormControl
      fullWidth
      error={hasError}
      required={required || false}
      disabled={disabled}
    >
      <InputLabel error={!!error}>{label}</InputLabel>
      <Select
        variant={variant || "outlined"}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        error={!!error}
        size={size || ""}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export default SelectInput;
