import { Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  GRADE_OPTIONS,
  SEX_OPTIONS,
  SHIRT_OPTIONS,
} from "../../helpers/list-options";
import { regActions } from "../../store/register/slice";
import DateInput from "../UI/DateInput";
import SelectInput from "../UI/SelectInput";
import TextInput from "../UI/TextInput";
import { TYPE_CCATS } from "../../store/account/constants";
import PageHeading from "../UI/PageHeading";

// form that collects required participant information to register for a program
const CamperForm = ({ label }) => {
  const { form, errors, disabled } = useSelector((state) => state.register);
  const programType = useSelector((state) => state.account.programType);
  const dispatch = useDispatch();

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    // update form state value for given input name
    dispatch(regActions.setForm({ name, value }));
  }, []);

  if (programType === TYPE_CCATS) {
    return (
      <>
        <PageHeading text={label} />
        <TextInput
          type="text"
          name="firstName"
          label="First Name"
          value={form.firstName}
          onChange={handleChange}
          required
          error={errors.firstName}
          disabled={disabled.firstName}
        />
        <TextInput
          type="text"
          name="lastName"
          label="Last Name"
          value={form.lastName}
          onChange={handleChange}
          required
          error={errors.lastName}
          disabled={disabled.lastName}
        />
        <SelectInput
          name="grade"
          label="Grade"
          value={form.grade}
          onChange={handleChange}
          options={GRADE_OPTIONS}
          required
          error={errors.grade}
          disabled={disabled.grade}
        />
        <SelectInput
          name="sex"
          label="Sex"
          value={form.sex}
          onChange={handleChange}
          options={SEX_OPTIONS}
          required
          error={errors.sex}
          disabled={disabled.sex}
        />
        <SelectInput
          name="shirtSize"
          label="T-Shirt Size (at time of camp)"
          value={form.shirtSize}
          onChange={handleChange}
          options={SHIRT_OPTIONS}
          required
          error={errors.shirtSize}
          disabled={disabled.shirtSize}
        />
      </>
    );
  }

  return (
    <>
      <PageHeading text={label} />
      <TextInput
        type="text"
        name="firstName"
        label="First Name"
        value={form.firstName}
        onChange={handleChange}
        required
        error={errors.firstName}
        disabled={disabled.firstName}
      />
      <TextInput
        type="text"
        name="lastName"
        label="Last Name"
        value={form.lastName}
        onChange={handleChange}
        required
        error={errors.lastName}
        disabled={disabled.lastName}
      />
      <DateInput
        name="birthdate"
        label="Birthdate"
        value={form.birthdate}
        onChange={handleChange}
        required
        error={errors.birthdate}
        disabled={disabled.birthdate}
      />
      <SelectInput
        name="sex"
        label="Sex"
        value={form.sex}
        onChange={handleChange}
        options={SEX_OPTIONS}
        required
        error={errors.sex}
        disabled={disabled.sex}
      />
      <SelectInput
        name="shirtSize"
        label="T-Shirt Size (at time of camp)"
        value={form.shirtSize}
        onChange={handleChange}
        options={SHIRT_OPTIONS}
        required
        error={errors.shirtSize}
        disabled={disabled.shirtSize}
      />
    </>
  );
};

CamperForm.propTypes = {
  label: PropTypes.string.isRequired,
};

export default CamperForm;
