import { DialogContentText } from "@mui/material";
import { Stack } from "@mui/material";
import { Box, Divider, Link, Typography } from "@mui/material";
import { useState } from "react";
import ScrollableDialog from "../UI/ScrollableDialog";

const TermsAndConditions = ({ isRegistering }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const content = (
    <Stack spacing={2}>
      <Divider variant="middle">
        <DialogContentText variant="h6" sx={{ fontWeight: "bold" }}>
          Deposit
        </DialogContentText>
      </Divider>
      <DialogContentText>The $100 deposit is nonrefundable</DialogContentText>
      <Divider variant="middle">
        <DialogContentText variant="h6" sx={{ fontWeight: "bold" }}>
          Transfer Policy
        </DialogContentText>
      </Divider>
      <DialogContentText>
        We understand that things change, and you may need to transfer your
        child from one week to another, so we make every effort to accommodate
        those requests. There is a $40 transfer fee for each session
        transferred.
      </DialogContentText>
      <Divider variant="middle">
        <DialogContentText variant="h6" sx={{ fontWeight: "bold" }}>
          Refund Policy
        </DialogContentText>
      </Divider>
      <DialogContentText>
        We offer full refunds minus your $100 deposit until four weeks before
        the program begins. This policy exists because we must purchase
        supplies, shirts, food and materials for your child in the weeks leading
        up to camp.
      </DialogContentText>
      <Divider variant="middle">
        <DialogContentText variant="h6" sx={{ fontWeight: "bold" }}>
          Behavior Policy
        </DialogContentText>
      </Divider>
      <DialogContentText>
        Participants must be able to function independently and as part of a
        group. They must be able to comprehend and follow basic instructions and
        safety measures set out by camp staff. They must have an understanding
        of natural hazards (for example, roads, lakes, and heights), and be able
        to change clothes and use restroom facilities without assistance. The
        Code of Conduct, which outlines prohibited behaviors must be signed by
        parents and participants. Participants who do not follow the behavior
        standards will be asked to withdraw from the program without a refund.
      </DialogContentText>
      <DialogContentText variant="caption">
        Payment in full is due four weeks prior to the start of the program or
        your spot will be subject to forfeiture.
      </DialogContentText>
    </Stack>
  );

  return (
    <Box textAlign="center">
      <ScrollableDialog
        title="Permissions"
        content={content}
        open={isOpen}
        setOpen={setIsOpen}
      />
      <Typography variant="caption">
        You accept the{" "}
        <Link onClick={open} sx={{ cursor: "pointer" }}>
          Terms &amp; Conditions
        </Link>{" "}
        by clicking {isRegistering ? '"Register & Pay"' : '"Pay Now"'}
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
