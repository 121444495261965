import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../UI/TextInput";
import { Button, Typography } from "@mui/material";

const ForgotPassword = ({ form, errors, onChange, redirectPath = "/" }) => {
  const navigate = useNavigate();
  const redirectLink = `${redirectPath}signin`;

  // go back to signin
  const handleBack = useCallback(() => {
    navigate(redirectLink);
  }, []);

  return (
    <>
      <Button variant="contained" onClick={handleBack} fullWidth color="accent">
        GO BACK
      </Button>
      <Typography align="center">
        Enter your account email address to reset your password
      </Typography>
      <TextInput
        label="Email"
        name="forgotEmail"
        value={form.forgotEmail}
        onChange={onChange}
        error={errors.forgotEmail}
        autoComplete="email"
      />
      <Button type="submit" variant="contained" fullWidth>
        SUBMIT
      </Button>
    </>
  );
};

export default ForgotPassword;
