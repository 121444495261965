import { Fragment } from "react";
import "./receipt.css";

const Receipt = ({ receipt }) => {
  function getSessionTitle(session) {
    if (session.discountName !== "") {
      return `${session.firstName} ${session.lastName}: ${session.programName}: ${session.num} (${session.discountName}: -$${session.discountAmount})`;
    } else
      return `${session.firstName} ${session.lastName}: ${session.programName}: ${session.num} (${session.dates})`;
  }

  return (
    <Fragment>
      <div className="invoice-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="top">
              <td colSpan="2">
                <table>
                  <tbody>
                    <tr>
                      <td className="title">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/logos/CUYLI-Black.png"
                          }
                          style={{ width: "40%", maxWidth: "300px" }}
                          alt="YLI Logo"
                        />
                      </td>
                      <td>
                        Invoice #: {receipt.invoiceNum}
                        <br />
                        Date: {receipt.date}
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="information">
              <td colSpan="2">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Youth Learning Institute
                        <br />
                        698 Concord Church Rd
                        <br />
                        Pickens, South Carolina 29678
                      </td>
                      <td>
                        {receipt.cardholder}
                        <br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr className="heading">
              <td>Card Type</td>

              <td>{receipt.cardType}</td>
            </tr>

            <tr className="details">
              <td>Card Number</td>

              <td>{receipt.cardNumber}</td>
            </tr>

            <tr className="heading">
              <td>Item</td>

              <td>Price</td>
            </tr>

            {receipt.sessions.map((session) => {
              return (
                <tr className="item last" key={Math.random()}>
                  <td>{getSessionTitle(session)}</td>
                  <td>${session.payment}</td>
                </tr>
              );
            })}
            <tr className="total">
              <td></td>

              <td>Total Paid: ${receipt.totalPayment}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
    </Fragment>
  );
};

export default Receipt;
