export const SIGN_IN = 0;
export const SIGN_UP = 1;
export const FORGOT_PASSWORD = 2;
export const SESSION_TIME = 10800000; // 3 hours
//export const SESSION_TIME = 120000; // 2 minutes

export const initialForm = {
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
  forgotEmail: "",
};

export const initialErrors = {
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
  forgotEmail: "",
};

export const initialState = {
  tokenExpiration: "",
  sessionExpiration: "",
  customerId: null,
  masquerading: false,
};
