import { createSlice } from "@reduxjs/toolkit";

import { initialState, PAY_DEPOSIT } from "./constants";

const isBeforeEbdCutoff = (ebdCutoff) => {
  const currentDate = new Date();
  try {
    // ensure timezone does not interfere with date comparison
    const ebdCutoffDate = new Date(
      new Date(ebdCutoff).toISOString().slice(0, -1)
    );
    // ebd is valid until the day after cutoff date
    ebdCutoffDate.setDate(ebdCutoffDate.getDate() + 1);
    return currentDate < ebdCutoffDate;
  } catch (e) {
    console.log(ebdCutoff);
    return false;
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCamper(state, { payload: data }) {
      const { firstName, lastName, birthdate, sex, shirtSize, camperKey } =
        data;
      if (
        state.campers.filter((camper) => camper.key === camperKey).length === 0
      ) {
        state.campers.push({
          key: camperKey,
          firstName,
          lastName,
          birthdate,
          sex,
          shirtSize,
        });
      }
    },
    addPriorCamper(state, { payload: data }) {
      const {
        firstName,
        lastName,
        birthdate,
        sex,
        shirtSize,
        camperKey,
        camperId,
      } = data;
      if (
        state.campers.filter((camper) => camper.key === camperKey).length === 0
      ) {
        state.campers.push({
          id: camperId,
          key: camperKey,
          firstName,
          lastName,
          birthdate,
          sex,
          shirtSize,
        });
      }
    },
    addRegistration(state, { payload: newReg }) {
      const camperRegistrations = state.registrations.filter(
        (r) => r.camperKey === newReg.camperKey
      );
      if (camperRegistrations.filter((r) => r.id === newReg.id).length === 0) {
        state.registrations.push(newReg);
        state.isRegistrationAdded = true;
      }
    },
    updateCamper(state, { payload: data }) {
      const { firstName, lastName, birthdate, sex, camperKey, shirtSize } =
        data;
      state.campers = state.campers.map((c) => {
        if (c.key === camperKey) {
          return {
            ...c,
            firstName,
            lastName,
            birthdate,
            sex,
            shirtSize,
          };
        }
        return c;
      });
    },
    updateRegistration(state, { payload: newReg }) {
      state.registrations = state.registrations.map((r) => {
        if (r.key === newReg.key) {
          return newReg;
        }
        return r;
      });
    },
    deleteCamper(state, { payload: camperKey }) {
      state.registrations = state.registrations.filter(
        (reg) => reg.camperKey !== camperKey
      );
      state.campers = state.campers.filter(
        (camper) => camper.key !== camperKey
      );
    },
    deleteRegistration(state, { payload: regKey }) {
      state.registrations = state.registrations.filter(
        (reg) => reg.key !== regKey
      );
    },
    updatePayAmounts(state, { payload: programType }) {
      let total = 0;
      state.registrations = state.registrations.map((r) => {
        if (r.payOption === PAY_DEPOSIT) {
          r.payAmount = r.depositAmount;
        } else {
          let cost = r.totalCost;
          r.discounts.forEach((discount) => {
            cost -= discount.amount;
          });

          r.payAmount = cost;
        }

        // only add up the total for the selected program type
        // if (r.programType.includes(programType)) {
        //   total += r.payAmount;
        // }
        return r;
      });

      //state.totalPrice = total;
    },
    setTotalPrice(state, { payload: totalPrice }) {
      state.totalPrice = totalPrice;
    },
    updateRemainingBalances(state) {
      state.registrations = state.registrations.map((r) => {
        let remainingBalance = r.totalCost;
        if (r.payOption === PAY_DEPOSIT) {
          remainingBalance -= r.depositAmount;
          Object.entries(r.discounts).forEach(([key, value]) => {
            remainingBalance -= value;
          });
        } else {
          remainingBalance = 0;
        }

        r.remainingBalance = remainingBalance;
        return r;
      });
    },
    checkDiscounts(state) {
      state.registrations = state.registrations.map((reg) => {
        reg.discounts = [];
        const camper = state.campers.find((c) => c.key === reg.camperKey);
        // returning camper discount
        if (camper.id) {
          reg.discounts.return = reg.returnDiscount;
        }
        // early bird discount
        if (isBeforeEbdCutoff(reg.ebdCutoff)) {
          reg.discounts.ebd = reg.ebdAmount;
        }
        // special discount
        // if (reg.specialDiscount > 0) {
        //   reg.discounts.special = reg.specialDiscount;
        // }

        return reg;
      });
    },
    applyCoupon(state, { payload: discount }) {
      state.totalDiscount = discount.value;
      state.discountCode = discount.code;
    },
    setDigitalSignature(state, { payload: signature }) {
      state.digitalSignature = signature;
    },
    clearIsRegistrationAdded(state) {
      state.isRegistrationAdded = false;
    },
    resetForProgramType(state, { payload: programType }) {
      //remove campers and registrations that are associated with the provided program type
      let filteredRegs = state.registrations.filter(
        (r) => !r.programType.includes(programType)
      );
      //only remove campers that have no remaining registrations in other program types
      state.campers = state.campers.filter((c) => {
        return filteredRegs.some((r) => r.camperKey === c.key);
      });
      state.registrations = filteredRegs;
      state.totalPrice = 0;
      state.totalDiscount = 0;
      state.discountCode = "";
      state.digitalSignature = "";
      state.isRegistrationAdded = false;
    },
    reset: () => initialState,
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
