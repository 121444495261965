import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";

const SessionList = ({
  campers,
  sessions,
  handleAddSession,
  open,
  style,
  onClose,
}) => {
  const smallScreen = useMediaQuery("(max-width:750px)");

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, width: smallScreen ? "85%" : 500, padding: "15px" }}>
        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
          Select Sessions
        </Typography>
        {Object.keys(sessions).length > 0 ? (
          <List
            sx={{
              maxHeight: "250px",
              overflow: "auto",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {Object.keys(sessions).map((camperId) => {
              const camperSessions = sessions[camperId];
              return (
                <List key={camperId}>
                  <ListSubheader sx={{ bgcolor: "lightgray" }}>
                    {
                      campers.find((camper) => +camper.id === +camperId)
                        .fullName
                    }
                  </ListSubheader>
                  {camperSessions.map((session) => {
                    return (
                      <ListItem disablePadding key={session.id}>
                        <ListItemButton
                          onClick={() => handleAddSession(camperId, session.id)}
                        >
                          <ListItemText
                            primary={`${session.name} ${session.num}`}
                            secondary={`${session.dates}`}
                          />
                          <div>${session.totalDue}</div>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              );
            })}
          </List>
        ) : (
          <div>No sessions remaining</div>
        )}
        <Button onClick={onClose} sx={{ marginTop: "10px" }}>
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default SessionList;
