import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import {
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  initialForm,
  initialErrors,
} from "../../store/auth/constants";
import { uiActions } from "../../store/ui/slice";
import {
  AUTH_STEP,
  MSG_ACCOUNT_CREATED,
  MSG_PW_RESET,
} from "../../store/ui/constants";
import ForgotPassword from "./ForgotPassword";
import {
  useForgotPassword,
  useSignIn,
  useSignUp,
  useValidateToken,
} from "../../store/auth/hooks";
import Loader from "../UI/Loader";
import useStateValidation from "../../hooks/useStateValidation";
import {
  forgotPasswordValidations,
  signInValidations,
  signUpValidations,
} from "../../helpers/validations";
import { authActions } from "../../store/auth/slice";
import { Stack } from "@mui/material";
import { TYPE_CCATS } from "../../store/account/constants";

const AuthenticationPage = ({ type, redirectPath = "/" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState(initialErrors);

  // init validators
  const validateSignIn = useStateValidation(signInValidations, setErrors);
  const validateSignUp = useStateValidation(signUpValidations, setErrors);
  const validateForgotPassword = useStateValidation(
    forgotPasswordValidations,
    setErrors
  );
  const programType = useSelector((state) => state.account.programType);

  // init mutations
  const {
    submitValidateToken,
    isLoadingValidateToken,
    isErrorValidateToken,
    isSuccessValidateToken,
    validateTokenError,
  } = useValidateToken();

  const {
    submitSignIn,
    signInData,
    isLoadingSignIn,
    isErrorSignIn,
    isSuccessSignIn,
    signInError,
  } = useSignIn();

  const {
    submitSignUp,
    signUpData,
    isLoadingSignUp,
    isErrorSignUp,
    isSuccessSignUp,
    signUpError,
  } = useSignUp();

  const {
    submitForgotPassword,
    isLoadingForgotPassword,
    isErrorForgotPassword,
    isSuccessForgotPassword,
    forgotPasswordError,
  } = useForgotPassword();

  if (redirectPath === "/") {
    if (programType.includes(TYPE_CCATS)) {
      redirectPath = "/register/ccats";
    }
  }

  // set active step
  useEffect(() => {
    dispatch(uiActions.setActiveRegisterStep(AUTH_STEP));
  }, []);

  // check if already authenticated
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      submitValidateToken(token);
    }
  }, []);

  // redirect if already authenticated
  useEffect(() => {
    if (isErrorValidateToken) {
      console.error(validateTokenError);
    } else if (isSuccessValidateToken) {
      navigate(redirectPath);
    }
  }, [isErrorValidateToken, isSuccessValidateToken]);

  // update state with form input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // validate and submit api signin request
  const handleSignIn = () => {
    const { isValid } = validateSignIn(form);
    if (isValid) {
      const { email, password } = form;
      submitSignIn({ email, password });
    }
  };

  // handle signin success
  useEffect(() => {
    if (isSuccessSignIn) {
      dispatch(authActions.setTokenValues(signInData));
      dispatch(authActions.setMasquerading(false));
      dispatch({ type: "accountApi/invalidateTags", payload: ["Customer"] });
      navigate(redirectPath);
    }
  }, [isSuccessSignIn, signInData]);

  // handle signin error
  useEffect(() => {
    if (isErrorSignIn) {
      dispatch(uiActions.setAlertError(signInError.data));
    }
  }, [isErrorSignIn, signInError]);

  // validate and submit api signup request
  const handleSignUp = () => {
    const { isValid } = validateSignUp(form);
    if (isValid) {
      if (form.email !== form.confirmEmail) {
        setErrors({
          ...initialErrors,
          email: "Emails do not match",
          confirmEmail: "Emails do not match",
        });
        return;
      } else if (form.password !== form.confirmPassword) {
        setErrors({
          ...initialErrors,
          password: "Passwords do not match",
          confirmPassword: "Passwords do not match",
        });
        return;
      }
      const credentials = {
        email: form.email,
        confirmEmail: form.confirmEmail,
        password: form.password,
        confirmPassword: form.confirmPassword,
      };

      submitSignUp(credentials);
    }
  };

  // handle signup error
  useEffect(() => {
    if (isErrorSignUp) {
      dispatch(uiActions.setAlertError(signUpError.data));
    }
  }, [isErrorSignUp, signUpError]);

  // handle signup success
  useEffect(() => {
    if (isSuccessSignUp) {
      dispatch(authActions.clearInputs());
      dispatch(authActions.setTokenValues(signUpData));
      dispatch(uiActions.setAlertSuccess(MSG_ACCOUNT_CREATED));
      dispatch(authActions.setMasquerading(false));
      navigate(redirectPath);
    }
  }, [isSuccessSignUp, signUpData]);

  // validate and submit api forgot password request
  const handleForgotPassword = () => {
    const { isValid } = validateForgotPassword(form);
    if (isValid) {
      submitForgotPassword({ email: form.forgotEmail });
    }
  };

  // handle forgot password success
  useEffect(() => {
    if (isSuccessForgotPassword) {
      dispatch(authActions.clearInputs());
      dispatch(uiActions.setAlertSuccess(MSG_PW_RESET));
      navigate(redirectPath);
    }
  }, [isSuccessForgotPassword]);

  // handle forgot password error
  useEffect(() => {
    if (isErrorForgotPassword) {
      dispatch(uiActions.setAlertError(forgotPasswordError.data));
    }
  }, [isErrorForgotPassword, forgotPasswordError]);

  const onSubmit = (event) => {
    event.preventDefault();
    setErrors(initialErrors);
    switch (type) {
      case SIGN_IN:
        return handleSignIn();
      case SIGN_UP:
        return handleSignUp();
      case FORGOT_PASSWORD:
        return handleForgotPassword();
      default:
        throw new Error("Unknown authentication type");
    }
  };

  const renderContent = () => {
    if (
      isLoadingValidateToken ||
      isLoadingSignIn ||
      isLoadingSignUp ||
      isLoadingForgotPassword
    ) {
      return <Loader />;
    } else if (type === SIGN_IN) {
      return (
        <SignInForm
          form={form}
          errors={errors}
          onChange={handleChange}
          redirectPath={redirectPath}
        />
      );
    } else if (type === SIGN_UP) {
      return (
        <SignUpForm
          form={form}
          errors={errors}
          onChange={handleChange}
          redirectPath={redirectPath}
        />
      );
    } else if (type === FORGOT_PASSWORD) {
      return (
        <ForgotPassword
          form={form}
          errors={errors}
          onChange={handleChange}
          redirectPath={redirectPath}
        />
      );
    }
  };

  return (
    <Stack component="form" onSubmit={onSubmit} spacing={2}>
      {renderContent()}
    </Stack>
  );
};

AuthenticationPage.propTypes = {
  type: PropTypes.number.isRequired,
};

export default AuthenticationPage;
