import PropTypes from "prop-types";

import { FormHelperText, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

const TextAreaInput = ({
  rows,
  name,
  label,
  value,
  onChange,
  required,
  error,
  disabled,
  onClick,
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        variant="outlined"
        multiline
        name={name}
        rows={rows}
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        error={!!error}
        disabled={disabled}
        onClick={onClick}
      />
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};

TextAreaInput.propTypes = {
  rows: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TextAreaInput;
