export const CHOOSE_REGISTER_ACTION = 1;
export const NEW_CAMPER = 2;
export const NEW_REG = 3;
export const NEW_REG_PRIOR_CAMPER = 4;
export const EDIT_CAMPER = 5;
export const EDIT_REG = 6;
export const CONFIRM_PERMISSIONS = 7;

export const initialForm = {
  firstName: "",
  lastName: "",
  birthdate: null,
  grade: "", // C-CATS only
  sex: "",
  shirtSize: "",
  programId: "",
  sessionId: "",
  roommateReq: "",
};

export const initialErrors = {
  firstName: "",
  lastName: "",
  birthdate: "",
  grade: "",
  sex: "",
  shirtSize: "",
  programId: "",
  sessionId: "",
  roommateReq: "",
};

export const initialDisabled = {
  firstName: false,
  lastName: false,
  birthdate: false,
  grade: false,
  sex: false,
  shirtSize: false,
  programId: false,
  sessionId: false,
  roommateReq: false,
};

export const initialState = {
  form: initialForm,
  errors: initialErrors,
  disabled: initialDisabled,
  camperId: null,
  camperKey: null,
  regKey: null,
  currentAction: CHOOSE_REGISTER_ACTION,
};
