import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./constants";

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, { payload: error }) {
      console.log("Setting error to: ", error);
      state.error = error;
    },
    clearError(state) {
      state.error = initialState.error;
    },
  },
});

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
