import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCamperInfo from "../../../hooks/useCamperInfo";
import { useProcessPaymentMutation } from "../../../store/account/api";
import { uiActions } from "../../../store/ui/slice";
import MakePayment from "./MakePayment";
import { Box } from "@mui/system";
import { useSearchParams } from "react-router-dom";

const PaymentTab = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [payments, setPayments] = useState([]);
  // const [isPaying, setIsPaying] = useState(false);
  // const [totalPrice, setTotalPrice] = useState(0);

  const [searchParams] = useSearchParams();

  const {
    camperInfo: campers,
    camperError,
    isFetchingCamperInfo,
  } = useCamperInfo();

  if (isFetchingCamperInfo) return <div>Please wait...</div>;

  if (camperError) return <div>Error</div>;

  if (!campers) return null;

  function getSessions() {
    const selectedCamper = searchParams.get("camper");
    if (selectedCamper) {
      return campers.find((camper) => camper.id === +selectedCamper).sessions;
    } else {
      let sessions = [];
      campers.forEach((camper) => {
        camper.sessions.forEach((session) => {
          sessions.push(session);
        });
      });
      return sessions;
    }
  }

  function getOtherSessions() {
    const selectedCamper = searchParams.get("camper");
    if (selectedCamper) {
      let otherSessions = [];
      campers.forEach((camper) => {
        if (camper.id !== +selectedCamper) {
          camper.sessions.forEach((session) => {
            otherSessions.push(session);
          });
        }
      });
      return otherSessions;
    } else {
      return [];
    }
  }

  // const renderContent = () => {
  //   if (isPaying) {
  //     //todo: maybe show a list of sessions being paid for on the payment screen.
  //     //the payments state has session IDs, so we'd have to loop through campers to find session names
  //     return (
  //       <>
  //         <Button
  //           variant="contained"
  //           color="accent"
  //           onClick={handleGoBack}
  //           sx={{ marginTop: "16px", width: "150px" }}
  //         >
  //           Go Back
  //         </Button>
  //         <Typography variant="h5">Pay Now</Typography>
  //         <PaymentForm
  //           isRegistering={false}
  //           onSubmit={handleSubmit}
  //           totalPrice={totalPrice}
  //           onSubmitMsg="Processing payment..."
  //         />
  //       </>
  //     );
  //   }

  //   return (
  //     <>
  //       <SessionPaymentOptions
  //         label="Make a Payment"
  //         campers={campers}
  //         payments={payments}
  //         setPayments={setPayments}
  //         setTotalCost={setTotalPrice}
  //         totalCost={totalPrice}
  //       />
  //       <Button
  //         variant="contained"
  //         onClick={() => {
  //           setIsPaying(true);
  //           console.log(payments);
  //         }}
  //         sx={{ marginTop: "16px", width: "150px" }}
  //         disabled={payments.length === 0}
  //       >
  //         Next
  //       </Button>
  //     </>
  //   );
  // };

  // return (
  //   <Stack spacing={2} justifyContent="center" alignItems="center">
  //     {renderContent()}
  //   </Stack>
  // );

  return (
    <Box sx={{ marginTop: "16px" }}>
      <MakePayment
        chosenSessions={getSessions()}
        otherSessions={getOtherSessions()}
        campers={campers}
      />
    </Box>
  );
};

export default PaymentTab;
