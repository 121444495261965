import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { REGISTER_STEPS } from "../../store/ui/constants";
import HorizontalStepper from "./HorizontalStepper";
import CartToolbar from "../Cart/CartToolbar";
import { uiActions } from "../../store/ui/slice";
import AlertMessage from "./AlertMessage";
import PromptDialog from "./PromptDialog";
import ResponsiveDrawer from "./ResponsiveDrawer";
import Cart from "../Cart/Cart";

import { CssBaseline, Container, Toolbar, Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";

export const drawerWidth = 256;

const CartLayout = () => {
  const dispatch = useDispatch();
  const { mobileOpen, alert, activeRegisterStep } = useSelector(
    (state) => state.ui
  );

  const handleToggle = () => {
    dispatch(uiActions.setMobileOpen(!mobileOpen));
  };

  return (
    <Container
      sx={{ width: "50%", marginLeft: 0, marginRight: 0, height: "100vh" }}
      disableGutters
    >
      <CssBaseline />
      <AppBar position="fixed">
        <CartToolbar onDrawerToggle={handleToggle} />
      </AppBar>
      <Toolbar />
      <Box
        sx={{
          backgroundColor: "#F5F7F8",
          paddingLeft: "120px",
          paddingRight: "120px",
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F5F7F8",
            borderRadius: "8px",
            padding: "1rem",
          }}
        >
          <Outlet />
          <PromptDialog />
          <AlertMessage
            open={alert.open}
            message={alert.message}
            severity={alert.severity}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default CartLayout;
