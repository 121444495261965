import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import {
  CHOOSE_REGISTER_ACTION,
  CONFIRM_PERMISSIONS,
  EDIT_CAMPER,
  EDIT_REG,
  initialDisabled,
  initialErrors,
  initialForm,
  initialState,
  NEW_CAMPER,
  NEW_REG,
  NEW_REG_PRIOR_CAMPER,
} from "./constants";

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setForm(state, action) {
      const { name, value } = action.payload;
      state.form[name] = value;
      state.errors[name] = initialErrors[name];

      // clear selected session on program change
      if (name === "programId") {
        state.form.sessionId = initialForm.sessionId;
      }
      // TODO is this necessary? If so, should do the same thing for birthdate?
      if (name === "grade") {
        state.form.programId = initialForm.programId;
        state.form.sessionId = initialForm.sessionId;
      }
    },
    setInputError(state, action) {
      const { name, value } = action.payload;
      state.errors = {
        ...state.errors,
        [name]: value,
      };
    },
    setupChooseRegisterAction(state) {
      state.currentAction = CHOOSE_REGISTER_ACTION;
    },
    setupNewCamper(state) {
      state.camperKey = uuid();
      state.regKey = uuid();
      state.form = initialForm;
      state.errors = initialErrors;
      state.disabled = initialDisabled;
      state.currentAction = NEW_CAMPER;
    },
    setupPriorCamperRegistration(state, { payload: camper }) {
      // if the camper is in the cart without registrations, use that camper's key
      state.camperKey = camper.key ? camper.key : uuid();
      state.regKey = uuid();
      state.camperId = camper.id;
      state.form = {
        firstName: camper.firstName,
        lastName: camper.lastName,
        birthdate: camper.birthdate,
        grade: camper.grade,
        sex: camper.sex,
        shirtSize: camper.shirtSize,
        programId: initialForm.programId,
        sessionId: initialForm.sessionId,
        roommateReq: initialForm.roommateReq,
      };
      state.disabled = {
        ...initialDisabled,
        firstName: true,
        lastName: true,
        birthdate: camper.birthdate ? true : false,
        grade: false,
        sex: true,
      };
      state.errors = initialErrors;
      state.currentAction = NEW_REG_PRIOR_CAMPER;
    },
    setupNewRegistration(state, { payload: camper }) {
      state.camperKey = camper.key;
      state.camperId = camper?.id; // important if customer switches from prior camper to new camper
      state.regKey = uuid();
      state.form = {
        ...initialForm,
        firstName: camper.firstName,
        lastName: camper.lastName,
        birthdate: camper.birthdate,
        grade: camper.grade,
        sex: camper.sex,
        shirtSize: camper.shirtSize,
      };
      state.errors = initialErrors;
      state.disabled = initialDisabled;
      state.currentAction = NEW_REG;
    },
    setupEditCamper(state, { payload: camper }) {
      state.form = {
        firstName: camper.firstName,
        lastName: camper.lastName,
        birthdate: camper.birthdate,
        grade: camper.grade,
        sex: camper.sex,
        shirtSize: camper.shirtSize,
        programId: initialForm.programId,
        sessionId: initialForm.sessionId,
        roommateReq: initialForm.roommateReq,
      };
      state.errors = initialErrors;
      state.disabled = { ...initialDisabled, birthdate: true, sex: true };
      state.camperKey = camper.key;
      state.currentAction = EDIT_CAMPER;
    },
    setupEditRegistration(state, { payload: data }) {
      const { camper, registration: reg } = data;

      state.form = {
        firstName: camper.firstName,
        lastName: camper.lastName,
        birthdate: camper.birthdate,
        grade: camper.grade,
        sex: camper.sex,
        shirtSize: camper.shirtSize,
        programId: reg.programId,
        sessionId: reg.sessionId,
        roommateReq: reg.roommateReq,
      };
      state.errors = initialErrors;
      state.disabled = initialDisabled;
      state.camperKey = reg.camperKey;
      state.regKey = reg.key;
      state.currentAction = EDIT_REG;
    },
    setupDigitalConfirmation(state) {
      state.currentAction = CONFIRM_PERMISSIONS;
    },
    clearSessions(state) {
      state.sessions = [];
      state.form.sessionId = "";
    },
    clearForm(state) {
      state.form = initialForm;
      state.errors = initialErrors;
      state.disabled = initialDisabled;
    },
    lockCamperForm(state) {
      state.disabled = {
        ...state.disabled,
        firstName: true,
        lastName: true,
        birthdate: true,
        grade: true,
        sex: true,
      };
    },
    unlockCamperForm(state) {
      state.disabled = {
        ...state.disabled,
        firstName: false,
        lastName: false,
        birthdate: false,
        grade: false,
        sex: false,
      };
    },
    lockRegForm(state) {
      state.disabled = {
        ...state.disabled,
        programId: true,
        sessionId: true,
        roommateReq: true,
      };
    },
    unlockRegForm(state) {
      state.disabled = {
        ...state.disabled,
        programId: false,
        sessionId: false,
        roommateReq: false,
      };
    },
    clearKeys(state) {
      state.camperKey = state.regKey = null;
    },
  },
});

export const regActions = registerSlice.actions;

export default registerSlice.reducer;
