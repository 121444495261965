import { useGetCustomerInfoQuery } from "../store/account/api";

const useCustomerInfo = () => {
  const {
    data: customerInfo,
    error: customerError,
    isFetching: isFetchingCustomerInfo,
    isSuccess: isSuccessCustomerInfo,
    isUninitialized: isUninitializedCustomerInfo,
  } = useGetCustomerInfoQuery();

  return {
    customerInfo,
    customerError,
    isFetchingCustomerInfo,
    isSuccessCustomerInfo,
    isUninitializedCustomerInfo,
  };
};

export default useCustomerInfo;
