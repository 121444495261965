import { useDispatch } from "react-redux";

const useValidation = (validations, setInputError) => {
  const dispatch = useDispatch();

  const handleValidation = (form) => {
    let valid = true;
    if (validations) {
      const newErrors = {};
      for (const key in validations) {
        const value = form[key];
        const validation = validations[key];
        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
        }

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }
      }

      if (!valid) {
        for (const error in newErrors) {
          dispatch(
            setInputError({
              name: error,
              value: newErrors[error],
            })
          );
        }
      }
    }

    return valid;
  };

  return handleValidation;
};

export default useValidation;
