import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const PageHeading = ({ text }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", textAlign: "left", padding: 0 }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        color={theme.palette.headers.main}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default PageHeading;
