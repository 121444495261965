import React, { Fragment, useState } from "react";
import RegistrationsTable from "./RegistrationsTable";
import PastRegistrations from "./PastRegistrations";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { regActions } from "../../../store/register/slice";

const CamperRegistrations = ({ campers }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPast, setShowPast] = useState(false);

  function toggleHandler() {
    setShowPast((prevState) => !prevState);
  }

  function registerNewCamper() {
    dispatch(regActions.setupNewCamper());
    navigate("/");
  }

  // if there are no campers
  if (campers.length === 0) {
    return (
      <Stack direction="column">
        <p>You are not registered for any camps.</p>
        <Button
          variant="contained"
          onClick={() => navigate("/")}
          sx={{ width: "250px", height: "75px" }}
        >
          Register Now!
          <span style={{ marginLeft: "10px", fontSize: "1.5rem" }}>👉</span>
        </Button>
      </Stack>
    );
  }

  // if there are campers but no active registrations for the current program type
  if (campers.filter((camper) => camper.sessions.length > 0).length === 0) {
    return (
      <p>
        You have no active registrations for this program. Please visit{" "}
        <a href="/">register.yliapps.com/</a> to register.
      </p>
    );
  }

  return (
    <Fragment>
      {campers
        .filter((camper) => camper.isCurrent)
        .map((camper) => {
          return (
            <RegistrationsTable key={camper.id} camper={camper} past={false} />
          );
        })}
      <Stack direction="row" spacing={2}>
        {/* is there a better spot to put this than below everything? */}
        <Button
          variant="contained"
          style={{ marginBottom: "25px", marginTop: "1rem" }}
          onClick={registerNewCamper}
        >
          Register New Camper
        </Button>
        {campers.filter((camper) => camper.pastSessions.length > 0).length >
          0 && (
          <Button
            variant="contained"
            onClick={toggleHandler}
            style={{ marginBottom: "25px", marginTop: "1rem" }}
          >
            {`${showPast ? "Hide" : "View"} Past Registrations`}
          </Button>
        )}
      </Stack>
      {showPast && <PastRegistrations campers={campers} />}
    </Fragment>
  );
};

export default React.memo(CamperRegistrations);
