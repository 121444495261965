import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAY_DEFAULT } from "../cart/constants";

const validateStatus = (response) => {
  return response.ok;
};

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // get list of prior campers for authenticated customer
    getPriorCampers: builder.query({
      query: ({ customerId, programType }) => ({
        url: `get_prior_campers`,
        params: { customerId, programType },
        validateStatus,
      }),
      transformResponse: (priorCampers) => {
        return priorCampers.map((camper) => ({
          id: camper.id,
          firstName: camper.first_name,
          lastName: camper.last_name,
          birthdate: new Date(camper.birthday).toISOString().slice(0, -1),
          grade: camper.grade ? camper.grade : "",
          sex: camper.gender,
          shirtSize: camper.tshirt_size,
        }));
      },
    }),
    // get list of program options for program type id
    getPrograms: builder.query({
      query: (programType) => ({
        url: `get_programs`,
        validateStatus,
        params: { programType },
      }),
      transformResponse: (programs) =>
        programs.map((program) => ({
          ...program,
          label: program.program_name,
          value: program.id,
        })),
    }),
    getProgram: builder.query({
      query: (programId) => ({
        url: `get_program/${programId}`,
        validateStatus,
      }),
      transformResponse: (program) => program,
    }),
    // get list of session options for selected program's id
    getSessions: builder.query({
      query: ({ programId, birthdate, grade, sex, camperId }) => ({
        url: `get_sessions/${programId}`,
        params: {
          birthdate,
          grade,
          sex,
          camperId,
        },
        validateStatus,
      }),
      keepUnusedDataFor: 10,
      transformResponse: (sessions) =>
        sessions.map((session) => {
          const sessionOption = {
            id: session.id,
            label: `${session.session_name}: ${session.dates}`,
            value: session.id,
            disabled: false,
          };
          // disable input option if session closed
          if (session.reason_closed) {
            sessionOption.label = `${sessionOption.label} (${session.reason_closed})`;
            sessionOption.disabled = true;
          }
          return sessionOption;
        }),
    }),
    getSession: builder.query({
      query: (sessionId) => ({
        url: `get_session/${sessionId}`,
        validateStatus,
      }),
      // default pay in full if not provided
      transformResponse: (session) => ({
        payOption: PAY_DEFAULT,
        discounts: [],
        ...session,
      }),
    }),
    getProgramType: builder.query({
      query: (name) => ({
        url: "get_active_program_type",
        params: { name },
        validateStatus,
      }),
    }),
    getPermissions: builder.query({
      query: () => ({
        url: "get_permissions",
        method: "GET",
        validateStatus,
      }),
      transformResponse: (permissions) =>
        permissions.map((permission) => ({
          id: permission.id,
          title: permission.title,
          content: permission.agreement,
        })),
    }),
  }),
});

export const {
  useGetPriorCampersQuery,
  useGetProgramsQuery,
  useGetProgramQuery,
  useGetSessionsQuery,
  useGetSessionQuery,
  useGetProgramTypeQuery,
  useGetPermissionsQuery,
} = registerApi;
